import React, {Fragment, PropsWithChildren} from "react";
import {useLocation} from "react-router-dom";
import {useMediaQuery} from "modules/utils";
import {AdvertRectangle} from "components/Advert";

const isHomeOrRegister = (pathname: string) => {
	const is_empty = pathname === "/";
	const second_param = pathname.split("/");

	if (second_param[1] === "registration") {
		return true;
	}

	return is_empty || pathname.includes("/superpole");
};

export const HOCAdvert: React.FC<PropsWithChildren> = ({children}) => {
	const {pathname} = useLocation();
	const is_mobile = useMediaQuery("(max-width: 960px)");
	const is_bottom_display = isHomeOrRegister(pathname) || is_mobile;
	return (
		<Fragment>
			{is_bottom_display ? null : <AdvertRectangle />}
			{children}
			{is_bottom_display ? <AdvertRectangle /> : null}
		</Fragment>
	);
};
