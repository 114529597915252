import React from 'react';
import styled from 'styled-components';
import { Direction, IICon } from 'modules/types';
import { cond, matches, constant } from 'lodash';

interface ISvgProps {
	to?: Direction;
}

const getDirectionValue = cond([
	[matches({ to: 'top' }), constant(-90)],
	[matches({ to: 'right' }), constant(-180)],
	[matches({ to: 'bottom' }), constant(-270)],
	[matches({ to: 'left' }), constant(0)],
]);

const Svg = styled.svg<ISvgProps>`
	transform: rotate(${getDirectionValue}deg);
`;

interface IProps extends IICon {
	to?: Direction;
}

export const ArrowSlim: React.FC<IProps> = ({ width = 9, height = 14, color = '#ffffff', ...rest }) => (
	<Svg width={`${width}px`} height={`${height}px`} viewBox="0 0 9 14" {...rest}>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g transform="translate(-7.000000, -5.000000)">
				<polygon fill={color} points="13.707 18.707 7 12 13.707 5.293 15.121 6.707 9.828 12 15.121 17.293"/>
			</g>
		</g>
	</Svg>
);

ArrowSlim.defaultProps = {
	width: 9,
	height: 14,
	color: '#FFFFFF',
	to: 'left',
};