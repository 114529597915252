import {
	IQuestionPredictionPayload,
	IPredictionSavePayload,
	ISagaAction,
	ILeaderboardRequest,
	ILeaderboardPayload,
	IError,
} from "modules/types";
import {call, put, select, take} from "typed-redux-saga";
import {Api, ApiError} from "modules/utils";
import {
	globalError,
	fetchUserPredictionSuccess,
	postPredictorPredictionSuccess,
	fetchGameBarSuccess,
	fetchLeaderBoardSuccess,
	getMoreLeaderboard,
	fetchLeaderBoard,
	showCrossPromotionModal,
} from "modules/actions";
import {getLeaderBoard} from "modules/selectors";

export const fetchUserPredictionSaga = function* ({
	payload,
}: ISagaAction<IQuestionPredictionPayload>) {
	try {
		const {data: response} = yield* call(Api.Predictor.show_my, payload);
		ApiError.CHECK(response);

		if (response.success) {
			if (response.success.prediction) {
				yield* put(postPredictorPredictionSuccess(response.success));
			} else {
				yield* put(fetchUserPredictionSuccess(response.success));
			}
		}
	} catch (e) {
		yield* put(globalError(e as IError));
	}
};

export const postPredictorPredictionSaga = function* ({
	payload,
}: ISagaAction<IPredictionSavePayload>) {
	try {
		const {data: response} = yield* call(Api.Predictor.save, payload);

		ApiError.CHECK(response);
		if (response.success) {
			yield* put(postPredictorPredictionSuccess(response.success));
			yield* put(showCrossPromotionModal());
		}
	} catch (e) {
		yield* put(globalError(e as IError));
	}
};

export const fetchGameBarSaga = function* ({payload}: ISagaAction<IQuestionPredictionPayload>) {
	try {
		const {data: response} = yield* call(Api.Predictor.game_bar, payload);
		ApiError.CHECK(response);

		if (response.success) {
			yield* put(fetchGameBarSuccess(response.success));
		}
	} catch (e) {
		yield* put(globalError(e as IError));
	}
};

export const fetchLeaderBoardSaga = function* ({payload}: ISagaAction<ILeaderboardPayload>) {
	try {
		const PAGE = 1;
		const page: number = payload.page || 0;
		const options: ILeaderboardRequest = {
			page,
			limit: 30,
			q: payload.q,
		};
		const leaderboard = yield* select(getLeaderBoard);

		const {data: response} = yield* call(Api.Predictor.leaderboard, options);
		ApiError.CHECK(response);

		const result = response.success;
		if (result) {
			const fresh_leaderboard = payload.is_load_more
				? [...leaderboard, ...result.rankings]
				: result.rankings;

			yield* put(
				fetchLeaderBoardSuccess({
					...result,
					rankings: fresh_leaderboard,
				})
			);

			if (result.nextPage) {
				yield* take(getMoreLeaderboard);
				yield* put(fetchLeaderBoard({q: payload.q, page: page + PAGE, is_load_more: true}));
			}
		}
	} catch (e) {
		yield* put(globalError(e as IError));
	}
};
