import React from 'react';
import styled from 'styled-components';
import { eq } from 'lodash';

const PrivacyRadioWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position:relative;
    color: #C80502;
    background-color: #fff;
    border-radius: 30px;
    border: 1px solid #C80502;
    height: 40px;
    
    >*{
        height: 40px;
        display: block;
        width: 50%;
        margin: 0;
        box-sizing: border-box;
        cursor: pointer;
        position: absolute;
        top: 0;
    }
    
    >label{
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        border: 1px solid transparent;
        border-radius: 30px;
        text-transform: uppercase;
        font-weight: 600;
        
        &:first-child{
            left: 0;
        }
        
        &:last-child{
            right: 0;
        }
    }
    
    >input{
        opacity: 0;
        z-index: 1;
        
        &:checked + label{
            background-color: #C80502;
            color: #fff;
            z-index: 3;
        }
    }
`;

interface IPrivacyRadio {
	required?: boolean;
	defaultChecked?: string;
	labelOne: string;
	labelTwo: string;
	name: string;
	onChange?: () => void;
}

export const RadioSwitcher: React.FC<IPrivacyRadio> = ({
														   required,
														   defaultChecked,
														   labelOne,
														   labelTwo,
														   onChange,
														   name
													   }) =>
	(<PrivacyRadioWrapper>
		<input required={required} type="radio" id={labelOne.toLocaleLowerCase()} name={name}
			   value={labelOne.toLocaleLowerCase()} onChange={onChange}
			   defaultChecked={eq(labelOne.toLocaleLowerCase(), defaultChecked)}/>
		<label htmlFor={labelOne.toLocaleLowerCase()}>{labelOne}</label>
		<input required={required} type="radio" id={labelTwo.toLocaleLowerCase()} name={name}
			   value={labelTwo.toLocaleLowerCase()}
			   defaultChecked={eq(labelTwo.toLocaleLowerCase(), defaultChecked)} onChange={onChange}/>
		<label htmlFor={labelTwo.toLocaleLowerCase()}>{labelTwo}</label>
	</PrivacyRadioWrapper>);

export default RadioSwitcher;
