import {createAction} from "redux-act";

export const hideCrossPromotionModal = createAction();
export const showCrossPromotionModal = createAction();

export const showModalLogin = createAction();
export const hideModalLogin = createAction();

export const showModalRegistration = createAction();
export const hideModalRegistration = createAction();

export const showModalForgottenPassword = createAction();
export const hideModalForgottenPassword = createAction();

export const showModalChangePassword = createAction();
export const hideModalChangePassword = createAction();
