import {createReducer} from "redux-act";
import * as actions from "modules/actions";

export interface IContactReducerState {
	contact_form_sent: boolean;
	contact_form_disabled: boolean;
}

const initialState: IContactReducerState = {
	contact_form_sent: false,
	contact_form_disabled: false,
};

export interface IPostContactForm {
	name: string;
	email: string;
	category: string;
	question: string;
}

export const contact = createReducer({}, initialState)
	.on(actions.postContactForm, (state) => ({
		...state,
		contact_form_disabled: true,
	}))
	.on(actions.postContactFormSuccess, (state) => ({
		...state,
		contact_form_sent: true,
	}))
	.on(actions.refreshContactForm, (state) => ({
		...state,
		contact_form_disabled: false,
		contact_form_sent: false,
	}))
	.on(actions.postContactFormFailed, () => initialState);
