import React, {SyntheticEvent} from "react";
import styled from "styled-components";

interface IInputStyled {
	has_error?: boolean;
}

export interface IInput extends IInputStyled {
	clear?: boolean;
	callback?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
}

export const InputStyled = styled.input<IInputStyled>`
	background-color: #ffffff;
	border: 1px solid ${({has_error}) => (has_error ? "#C80502" : "#D2D2D2")};
	padding-left: 12px;
	font-size: 12px;
	color: #232323;
	line-height: 14px;
	height: 40px;
	width: 100%;
	box-sizing: border-box;
	outline: none;
`;

const InputWrapper = styled.div`
	position: relative;
`;

interface IProps {
	has_error: boolean;
	clear?: boolean;
	value?: string | number;
	index?: number;
	type?: string;
	name?: string;
	placeholder?: string;
	required?: boolean;
	title?: string;
	defaultValue?: string;
	tabIndex?: number;
	onChange?: (e: SyntheticEvent<HTMLInputElement, Event>) => void;
	autoComplete?: string;
}

export const Input: React.FC<IProps> = ({has_error, clear, value, index, ...rest}) => {
	return (
		<InputWrapper>
			<InputStyled defaultValue={value} has_error={has_error} {...rest} />
		</InputWrapper>
	);
};

export default Input;
