const secreteKey = `worldSBK-isSecretPassed`;
export abstract class SecretGateController {
	private static _count = 0;

	static get IS_SECRET_PASSED() {
		const IS_SECRET_ENABLED = Boolean(JSON.parse(process.env.REACT_APP_HIDE_LOGIN || "false"));
		console.log(IS_SECRET_ENABLED);

		if (IS_SECRET_ENABLED) {
			return Boolean(JSON.parse(sessionStorage.getItem(secreteKey) || "false"));
		}

		return true;
	}

	public static handleSecretClick = () => {
		console.log(111);
		if (SecretGateController.IS_SECRET_PASSED) {
			return;
		}

		SecretGateController._count += 1;

		console.log(SecretGateController._count);

		if (SecretGateController._count >= 10) {
			sessionStorage.setItem(secreteKey, "true");
			window.location.reload();
		}
	};
}
