import facebook from "assets/img/facebook.svg";
import twitter from "assets/img/twitter.svg";
import instagram from "assets/img/instagram.svg";
import youtube from "assets/img/youtube.svg";
import {ReactComponent as TickTok} from "assets/img/share_icons/tiktok.svg";
import React from "react";
import styled from "styled-components";
import {Container} from "components";
import company_logo from "assets/img/Genius.svg";
import logo from "assets/img/logo.png";

const FooterWrapper = styled.footer`
	background: #000;
	color: #fff;
	padding: 70px 0 45px;
	margin-top: auto;

	img {
		display: block;
	}

	@media screen and (max-width: 500px) {
		padding: 32px 0 40px;
	}
`;

const Nav = styled.nav`
	margin-bottom: 50px;

	@media screen and (max-width: 500px) {
		text-align: center;
		margin-bottom: 20px;
		a {
			margin-bottom: 15px;
		}
	}
`;

const NavItem = styled.a`
	display: inline-block;
	border-bottom: 1px solid #fff;
	font-family: var(--fontFamilySecondary);
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 118%;
	padding-bottom: 6px;
	cursor: pointer;

	&:hover {
		background: none;
	}
	margin-right: 15px;

	&:last-child {
		margin-right: 0;
	}
`;

const CopyrightWrapper = styled.div`
	font-size: 10px;
	line-height: 18px;
	max-width: 1000px;
	margin-bottom: 50px;

	p {
		font-family: var(--fontFamilySecondary);
	}

	@media screen and (max-width: 500px) {
		text-align: center;
	}
`;

const LogoWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	a:last-child {
		margin-left: auto;
	}

	@media screen and (max-width: 500px) {
		justify-content: space-around;

		img {
			margin: 0 10px 35px;
		}
		a:last-child {
			margin-right: auto;
		}
	}
`;

const SiteLogo = styled.a`
	margin-right: 40px;
	img {
		width: 200px;
	}

	@media screen and (max-width: 500px) {
		margin: 0;
	}
`;

const Socials = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	a {
		margin-left: 15px;
		padding-bottom: 6px;
		color: #fff;
		.facebook-link {
			height: 22px;
		}
		.instagram-link {
			height: 24px;
		}
		.twitter-link {
			width: 22px;
		}
		.youtube-link {
			width: 24px;
		}
	}

	p {
		color: #ffffff;
		font-family: var(--fontFamilySecondary);
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		border-bottom: 1px solid #fff;
		line-height: 118%;
		padding-bottom: 6px;
	}

	margin-bottom: 30px;
`;

const NavWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	@media screen and (max-width: 992px) {
		flex-flow: column;
		align-items: center;

		> ${Socials} {
			order: -1;
		}
	}
`;

const currentYear = new Date().getFullYear();

export const Footer: React.FC = () => (
	<FooterWrapper>
		<Container>
			<NavWrapper>
				<Nav>
					<NavItem
						rel="noopener noreferrer"
						target="_blank"
						href="https://www.predictor.worldsbk.com/help/terms-and-conditions">
						Terms & Conditions
					</NavItem>
					<NavItem
						rel="noopener noreferrer"
						target="_blank"
						href="https://sso.dorna.com/en/privacy-policy">
						Privacy Policy
					</NavItem>
					<NavItem
						rel="noopener noreferrer"
						target="_blank"
						href="https://www.worldsbk.com/en/cookie_policy">
						Cookie Policy
					</NavItem>
					<NavItem
						rel="noopener noreferrer"
						target="_blank"
						href="https://www.worldsbk.com/en/terms_conditions">
						Terms of Use
					</NavItem>
				</Nav>
				<Socials>
					<p>Follow WorldSBK: </p>
					<a
						href="https://www.facebook.com/WorldSBK"
						target="_blank"
						rel="noopener noreferrer">
						<img src={facebook} alt="facebook" className={"facebook-link"} />
					</a>
					<a
						href="https://instagram.com/WorldSBK"
						target="_blank"
						rel="noopener noreferrer">
						<img src={instagram} alt="instagram" className={"instagram-link"} />
					</a>
					<a
						href="https://www.tiktok.com/@WorldSBK?lang=en"
						target="_blank"
						rel="noopener noreferrer">
						<TickTok />
					</a>
					<a
						href="https://twitter.com/WorldSBK"
						target="_blank"
						rel="noopener noreferrer">
						<img src={twitter} alt="twitter" className={"twitter-link"} />
					</a>
					<a
						href="https://www.youtube.com/WorldSBK"
						target="_blank"
						rel="noopener noreferrer">
						<img src={youtube} alt="youtube" className={"youtube-link"} />
					</a>
				</Socials>
			</NavWrapper>
			<CopyrightWrapper>
				<p>
					© {currentYear} Dorna Sports SL. All rights reserved. All trademarks are the
					property of their respective owners.
				</p>
			</CopyrightWrapper>

			<LogoWrapper>
				<SiteLogo href="https://www.worldsbk.com/">
					<img src={logo} alt="WorldSBK™" />
				</SiteLogo>
				<a
					href="https://geniussports.com/media-engagement/free-to-play-games/"
					rel="noopener noreferrer"
					target="_blank">
					<img src={company_logo} alt="Powered by Genius Sports" />
				</a>
			</LogoWrapper>
		</Container>
	</FooterWrapper>
);
