import {PointsType} from "modules/utils/enums";

const JSON_URL = process.env.REACT_APP_JSON_URL || "";
const API_URL = process.env.REACT_APP_API_URL || "";
export const ENV = process.env.REACT_APP_ENVIRONMENT;
export const BASE_URL = process.env.REACT_APP_BASE_URL || "";
const STORAGE_VERSION = process.env.REACT_APP_STORAGE_VERSION || "";
const SECRET = process.env.REACT_APP_SECRET || "false";
export const SSO_URL = process.env.REACT_APP_SSO_URL || "";
export const SSO_URL_SCRIPT = process.env.REACT_APP_SSO_URL_SCRIPT || "";
const LOGIN_BUTTONS_URL = process.env.REACT_APP_LOGIN_BUTTONS_URL || "";
export const IS_LOCALHOST = process.env.REACT_APP_IS_LOCALHOST;
export const IS_OFF_SEASON = JSON.parse(process.env.REACT_APP_IS_OFF_SEASON || "false");
export const REACT_APP_LINK = process.env.REACT_APP_LINK || "";
export const FANTASY_REDIRECT_LINK = process.env.REACT_APP_FANTASY_REDIRECT_LINK || "";
export const SHARE_MESSAGE_GLOBAL = `Predict the next WorldSBK Superpole lap time! Try your luck! The closer you are to the time, the better your score and the more chance you have of winning a Tissot watch.`;
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL || "";
export const FLAGS_URL = IMAGES_URL + "flags/";
export const RIDERS_URL = IMAGES_URL + "riders/";
export const TRACKS_URL = IMAGES_URL + "tracks/";
export const HIDE_LOGIN = JSON.parse(process.env.REACT_APP_HIDE_LOGIN || "false");
export const PREDICTION_RESULTS = {
	[PointsType.Perfect]: {
		color: "#00AB30",
		text: "perfect prediction!",
		share_message:
			"Spot on, I guessed the perfect Superpole time! I predicted the exact Minutes, Seconds and Thousandths. Do you want to try your luck?",
	},
	[PointsType.HalfASecond]: {
		color: "#F49C0A",
		text: "within 250 thousandths",
		share_message:
			"How close was that! I scored 60 points in the World SBK Predictor! I was less than a quarter of a second away from the Superpole lap time. Do you want to give it a try?",
	},
	[PointsType.WithinASecond]: {
		color: "#BCBCBC",
		text: "within half of a second",
		share_message:
			"How close was that! I scored 40 points in the World SBK Predictor. I was less than half a second away from the Superpole lap time. Do you want to give it a try?",
	},
	[PointsType.WithinTwoSeconds]: {
		color: "#B14403",
		text: "within 1 second",
		share_message:
			"How close was that! I scored 20 points in the World SBK Predictor. I was less than a second away from the Superpole lap time. Do you want to give it a try?",
	},
	[PointsType.BeyondTwoSeconds]: {
		color: "#FFFFFF",
		text: "over 1 sec",
		share_message:
			"Oh no! I didn't manage to guess the Superpole lap time in the the World SBK Predictor this week, let's hope I have better luck next time!",
	},
};

export const FANTASY_LINK = process.env.REACT_APP_FANTASY_LINK || "";

const DORNA_ACCOUNT_LINK = process.env.REACT_APP_DORNA_ACCOUNT_LINK || "";
export const ACCOUNT_LINK = `${DORNA_ACCOUNT_LINK}#predictor`;

export abstract class Constant {
	public static get LOGIN_BUTTONS_URL() {
		return LOGIN_BUTTONS_URL;
	}

	public static get SSO_URL_SCRIPT() {
		return SSO_URL_SCRIPT;
	}

	public static get API_URL() {
		return API_URL;
	}

	public static get JSON_URL() {
		return JSON_URL;
	}
	public static get BASE_URL() {
		return BASE_URL;
	}

	public static get STORAGE_VERSION() {
		return STORAGE_VERSION;
	}

	public static get SECRET() {
		return SECRET;
	}
}

export const HELP_JSON_URL = `${REACT_APP_LINK}help_center/en-us/`;

export const PASSWORD_REGEXP_STR = "^((?=.*?\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^])).{8,}$";

export const IS_PRESEASON_BACKGROUND = JSON.parse(
	process.env.REACT_APP_IS_PRESEASON_BACKGROUND || "false"
);
