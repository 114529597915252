import {put, call} from "typed-redux-saga";
import * as actions from "modules/actions";
import {IApiResponse, IError, IPostContactForm, ISagaAction} from "modules/types";
import {Api, ApiError} from "modules/utils";

export const postContactFormSaga = function* (action: ISagaAction<IPostContactForm>) {
	try {
		const {data: response} = yield* call(Api.Contact.save, action.payload);
		ApiError.CHECK(response as IApiResponse);

		yield* put(actions.postContactFormSuccess());
	} catch (e) {
		yield* put(actions.postContactFormFailed(e as IError));
	}
};
