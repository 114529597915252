import styled from 'styled-components';

export const ModalOverlay = styled.div`
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, .5);
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	padding: 20px;
	z-index: 1000;
`;