import React from 'react';
import { IICon } from 'modules/types';

export const IconClose: React.FC<IICon> = ({ color = '#000000', width = 14, height = 14 }) => (
	<svg width={`${width}px`} height={`${height}px`} viewBox="0 0 14 14">
		<title>Close</title>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g transform="translate(-5.000000, -5.000000)" fill={color}>
				<polygon points="17.297363 5 19 6.70263697 13.7021227 11.9992613 19 17.297363 17.297363 19 11.9992613 13.7021227 6.70263697 19 5 17.297363 10.297363 11.9992613 5 6.70263697 6.70263697 5 11.9992613 10.297363"/>
			</g>
		</g>
	</svg>
);

IconClose.defaultProps = {
	color: '#000000',
	width: 14,
	height: 14,
};