import React from "react";
import styled, {css} from "styled-components";

const Input = styled.input`
	position: absolute;
	left: -99999px;
	opacity: 0;
`;

interface ILabelTextProps {
	isActive: boolean;
}

const LabelText = styled.span<ILabelTextProps>`
	display: flex;
	width: 50%;
	flex: 0 0 50%;
	align-items: center;
	justify-content: center;
	color: #000;
	font-family: var(--fontFamilyBase);
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	letter-spacing: 0.02em;
	border-radius: 4px;
	height: 100%;
	box-sizing: border-box;
	user-select: none;

	${({isActive}) =>
		isActive
			? css`
					background: var(--primaryColor);
			  `
			: ""}

	@media screen and (max-width: 960px) {
		font-size: 12px;
	}
`;

const Label = styled.label`
	background: #fff;
	position: relative;
	overflow: hidden;
	box-sizing: border-box;
	padding: 8px;
	width: 335px;
	height: 48px;
	border: 1px solid #bcbcbc;
	display: flex;
	cursor: pointer;
	@media screen and (max-width: 960px) {
		width: 100%;
	}
`;

interface IProps {
	labelOne: string;
	labelTwo: string;
	checked: boolean;
	onClick?: () => void;
}

export const Switch: React.FC<IProps> = ({labelOne, labelTwo, checked, onClick, ...rest}) => (
	<Label {...rest}>
		<Input onChange={onClick} type="checkbox" checked={checked} />
		<LabelText isActive={!checked}>{labelOne}</LabelText>
		<LabelText isActive={checked}>{labelTwo}</LabelText>
	</Label>
);

export default Switch;
