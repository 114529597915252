import React, {Fragment, PropsWithChildren, useCallback} from "react";
import styled from "styled-components";

import image_365 from "assets/img/crosspromo/SBK_TISSOT_T_RACE_BANNERS.png";
import image_728 from "assets/img/crosspromo/SBK_TISSOT_T_RACE_BANNERS2.png";
import image_980 from "assets/img/crosspromo/SBK_TISSOT_T_RACE_BANNERS3.png";
import image_1240 from "assets/img/crosspromo/SBK_TISSOT_T_RACE_BANNERS4.png";
import image_1472 from "assets/img/crosspromo/SBK_TISSOT_T_RACE_BANNERS5.png";

import {Exist} from "components/Exist";
import {useDispatch, useSelector} from "react-redux";
import cross from "assets/img/cross.svg";
import {hideCrossPromotionModal} from "modules/actions";
import {isNeedToShowCrossPromotion} from "modules/selectors/modals";

const CrossPromotion = styled.div`
	position: fixed;
	bottom: 0;
	z-index: 13;
	margin: 0 auto 20px;
	width: 100%;
	text-align: center;
	a {
		max-width: 100%;
		img {
			max-width: 90%;
			@media screen and (max-width: 450px) {
				max-width: 75%;
			}
		}
	}
`;
const CloseBtn = styled.button`
	background: url(${cross}) center transparent no-repeat;
	width: 40px;
	height: 40px;
	border: none;
	outline: none;
	position: absolute;
	top: 0;
	cursor: pointer;
`;

const LinkImage = () => (
	<a
		target="_blank"
		rel="noopener noreferrer"
		href="https://www.tissotwatches.com/en-en/t1414171103100.html?utm_source=sponsoring&utm_medium=banner&utm_campaign=sbk_predictor_2024">
		<picture>
			<source srcSet={image_365} media="(max-width: 750px)" />
			<source srcSet={image_728} media="(max-width: 1000px)" />
			<source srcSet={image_980} media="(max-width: 1260px)" />
			<source srcSet={image_1240} media="(max-width: 1492px)" />

			<img src={image_1472} alt="Predictor" />
		</picture>
	</a>
);
export const HOCCrossPromotion: React.FC<PropsWithChildren> = ({children}) => {
	const dispatch = useDispatch();
	const isNeedToShow = useSelector(isNeedToShowCrossPromotion);

	const hide = useCallback(() => {
		dispatch(hideCrossPromotionModal());
		// PersistStorage.SET("CrossPromotionHide", "true");
	}, [dispatch]);

	return (
		<Fragment>
			<Exist when={isNeedToShow}>
				<CrossPromotion>
					<LinkImage />
					<CloseBtn onClick={hide} />
				</CrossPromotion>
			</Exist>

			{children}
		</Fragment>
	);
};
