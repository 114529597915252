import React, {useCallback, useState} from "react";
import {
	CloseModalButton,
	ModalDescription,
	ModalInner,
	ModalOverlay,
	ModalTitle,
} from "components/Modal";
import {ButtonPrimary, FieldText} from "components/FormElements";
import {useDispatch, useSelector} from "react-redux";
import {isForgotPassRequestSuccess, isForgottenPasswordFormVisible} from "modules/selectors";
import styled from "styled-components";
import {FormControl, InputLabel} from "@mui/material";
import {IconClose} from "components/Icons";
import {hideModalForgottenPassword, postForgotPassword} from "modules/actions";
import ErrorIcon from "@mui/icons-material/Error";
import {Exist} from "components/Exist";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const FormControlStyled = styled(FormControl)``;

const Details = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	.icon {
		color: #f44336;
		font-size: 42px;
		margin-top: 20px;

		&.icon-check {
			color: #000;
		}
	}

	${ModalTitle} {
		margin: 20px auto;
	}

	form {
		width: 440px;
		max-width: 100%;
		display: flex;
		flex-direction: column;
		* > {
			box-sizing: border-box;
		}
	}

	${FormControlStyled} {
		width: 100%;
		margin-bottom: 20px;
	}

	${ButtonPrimary} {
		width: 100%;
		max-width: 100%;
	}
`;

export const ModalForgottenPassword: React.FC = () => {
	const isVisible = useSelector(isForgottenPasswordFormVisible);
	const [form, changeForm] = useState({email: ""});
	const [formValidity, setIsFormValid] = useState({email: false});
	const dispatch = useDispatch();
	const isSuccess = useSelector(isForgotPassRequestSuccess);

	const handleForm = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const {value, name, validity} = e.currentTarget;

			changeForm({
				...form,
				[name]: value,
			});
			setIsFormValid({
				...formValidity,
				[name]: validity.valid,
			});
		},
		[form, formValidity]
	);

	const submitForm = useCallback(
		(e: React.SyntheticEvent<HTMLFormElement>) => {
			e.preventDefault();
			e.stopPropagation();
			dispatch(postForgotPassword(form));
		},
		[dispatch, form]
	);

	const closeModal = () => {
		dispatch(hideModalForgottenPassword());
	};

	if (!isVisible) {
		return null;
	}
	const isDisabled = !formValidity.email;

	return (
		<ModalOverlay>
			<ModalInner>
				<CloseModalButton onClick={closeModal}>
					<IconClose />
				</CloseModalButton>
				<Exist when={isSuccess}>
					<Details>
						<CheckCircleIcon className="icon icon-check" />
						<ModalTitle>Email Sent</ModalTitle>
						<ModalDescription>
							A link has been sent to your email address to reset your password. If it
							was not received please contact us or try with a different email
							address.
						</ModalDescription>
						<ButtonPrimary onClick={closeModal}>Close</ButtonPrimary>
					</Details>
				</Exist>
				<Exist when={!isSuccess}>
					<Details>
						<ErrorIcon className="icon" />

						<ModalTitle>Forgotten Password</ModalTitle>

						<ModalDescription>
							Please enter the email address you’re currently registered with.
						</ModalDescription>

						<form onSubmit={submitForm}>
							<FormControlStyled>
								<InputLabel htmlFor="email">Email Address</InputLabel>
								<FieldText
									onChange={handleForm}
									label="Email Address"
									name="email"
									type="email"
									id="email"
								/>
							</FormControlStyled>
							<ButtonPrimary disabled={isDisabled}>Submit</ButtonPrimary>
						</form>
					</Details>
				</Exist>
			</ModalInner>
		</ModalOverlay>
	);
};
