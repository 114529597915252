import React, {Fragment, useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {NavLink, useLocation} from "react-router-dom";
import {Container} from "components/Structure";
import {Exist} from "components";
import hamburger from "assets/img/hamburger.svg";
import {useDispatch, useSelector} from "react-redux";
import {isLoggedIn} from "modules/selectors";
import {SecretGateController, useMediaQuery, usePrevious} from "modules/utils";
import logo from "assets/img/logo.png";
import {RouteComponentProps} from "react-router";
import {showModalLogin} from "modules/actions";

interface IHeaderWrapperProps {
	is_open: boolean;
	is_home: boolean;
}

const Nav = styled.div`
	flex: 1;
	display: flex;
	align-items: center;

	&.right-align {
		justify-content: flex-end;
	}

	@media screen and (max-width: 960px) {
		flex-flow: column;
		justify-content: center;
		height: 100%;
		overflow: auto;
		text-align: center;
		margin-left: 0;
	}
`;

const SponsorBlock = styled.div`
	font-size: 10px;
	text-align: center;
	color: #fff;

	p {
		margin-bottom: 10px;
	}

	img {
		display: block;
		margin: 0 auto;
	}

	@media screen and (max-width: 960px) {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;

const HeaderWrapper = styled.header<IHeaderWrapperProps>`
	color: #fff;
	box-sizing: border-box;
	top: 0;
	left: 0;
	z-index: 15;
	width: 100%;
	background: #0f0f19;
	height: 80px;

	.logo {
		height: 80px;
		display: flex;
		align-items: center;
		img {
			width: 200px;
		}

		@media screen and (max-width: 960px) {
			width: 100%;
			justify-content: center;
		}
	}

	img {
		display: block;
	}

	${Container} {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		z-index: 15;
		> div,
		> nav {
			flex: 1;
		}
		@media screen and (max-width: 960px) {
			justify-content: flex-end;
		}
	}

	@media screen and (max-width: 960px) {
		position: ${({is_open}) => (is_open ? "fixed" : "relative")};

		${Nav},
		${SponsorBlock} {
			display: none;
		}
		img {
			width: 154px;
		}
	}
`;

const NavItem = styled(NavLink)<{ishome?: boolean}>`
	margin: 0 2px;
	padding: 2px 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	height: 80px;
	font-family: var(--fontFamilyBase);
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 118%;
	text-align: center;
	letter-spacing: 0.02em;
	transition: all 250ms ease-in-out;
	box-sizing: border-box;
	background: transparent;
	border: none;
	outline: none;
	cursor: pointer;

	&:hover,
	&.active {
		background: var(--primaryColor);
	}

	&.external {
		display: flex;
		align-items: center;

		&:hover,
		&.active {
			border-bottom: none;
		}
		svg {
			height: 39px;
			width: 70px;
		}
	}

	&.center {
		justify-content: center;
	}

	@media screen and (max-width: 960px) {
		margin: 0;
		color: #fff;
		&.active {
			border-radius: 0;
		}
		g {
			fill: #fff;
		}
	}
`;

const HamburgerMenu = styled.button`
	margin-right: 0;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	border: 0;
	cursor: pointer;
	background: none;
	position: absolute;
	left: 20px;
	img {
		width: 16px;
	}
`;

const MobileMenu = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	padding: 80px 0 100px;
	box-sizing: border-box;
	z-index: 14;
	color: #fff;
	display: none;

	@media screen and (max-width: 960px) {
		display: block;
	}
`;

const LogoutBtn = styled(NavLink)`
	padding: 0;
	cursor: pointer;
	font-family: var(--fontFamilyBase);
	font-size: 12px;
	line-height: 14px;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px auto;
	font-weight: 600;
	width: 100%;
	max-width: 240px;
	height: 40px;
	background: #fff;
	color: #cf003e;
	border: 1px solid #cf003e;
`;

const HomeLink = ({is_home, home_url}: {is_home?: boolean; home_url: string}) => {
	const homeClassName = is_home ? "home" : "";
	const isActive = (match: unknown, location: RouteComponentProps["location"]) => {
		return isHome(location.pathname);
	};
	return (
		<NavItem className={homeClassName} isActive={isActive} to={home_url}>
			Superpole
		</NavItem>
	);
};

const LeftNavigation = ({is_home, home_url}: {is_home: boolean; home_url: string}) => {
	const homeClassName = is_home ? "home" : "";
	const is_logged_in = useSelector(isLoggedIn);

	if (is_logged_in) {
		return (
			<Nav>
				<HomeLink is_home={is_home} home_url={home_url} />
				<NavItem to="/leaderboard">Leaderboard</NavItem>
				<NavItem to="/stats">Stats</NavItem>
				<NavItem to="/prizes">Prizes</NavItem>
				<NavItem className={homeClassName} to="/help">
					Help
				</NavItem>
			</Nav>
		);
	}

	return (
		<Nav>
			<HomeLink is_home={is_home} home_url={home_url} />
			<NavItem to="/prizes">Prizes</NavItem>
			<NavItem className={homeClassName} to="/help">
				Help
			</NavItem>
		</Nav>
	);
};

const RightNavigation = ({is_home}: {is_home: boolean}) => {
	const dispatch = useDispatch();
	const is_logged_in = useSelector(isLoggedIn);
	const homeClassName = is_home ? "home" : "";
	const showLoginModal = () => {
		dispatch(showModalLogin());
	};
	console.log();
	return (
		<Nav className={"right-align"}>
			{is_logged_in ? (
				<Fragment>
					<NavItem to="/account">Account</NavItem>
					<NavItem to="/logout">Logout</NavItem>
				</Fragment>
			) : (
				<Exist when={SecretGateController.IS_SECRET_PASSED}>
					<NavItem className={homeClassName} as={"button"} onClick={showLoginModal}>
						Log In
					</NavItem>
				</Exist>
			)}
		</Nav>
	);
};

const Navigation = ({home_url}: {home_url: string}) => {
	const is_logged_in = useSelector(isLoggedIn);
	const dispatch = useDispatch();
	const showLoginModal = () => {
		dispatch(showModalLogin());
	};

	return (
		<Nav>
			<HomeLink home_url={home_url} />
			{is_logged_in ? (
				<Fragment>
					<NavItem to="/leaderboard">Leaderboard</NavItem>
					<NavItem to="/stats">Stats</NavItem>
				</Fragment>
			) : null}

			<NavItem to="/prizes">Prizes</NavItem>
			<NavItem to="/help">Help</NavItem>
			{is_logged_in ? (
				<NavItem to="/account">Account</NavItem>
			) : (
				<Exist when={SecretGateController.IS_SECRET_PASSED}>
					<NavItem as={"button"} onClick={showLoginModal}>
						Log In
					</NavItem>
				</Exist>
			)}
			{is_logged_in ? <LogoutBtn to="/logout">Logout</LogoutBtn> : null}
		</Nav>
	);
};

const isHome = (pathname: string) => {
	const is_empty = pathname === "/";
	return is_empty || pathname.includes("/superpole");
};

export const Header = () => {
	const dispatch = useDispatch();
	const is_logged_in = useSelector(isLoggedIn);
	const {pathname} = useLocation();
	const prevPathname = usePrevious(pathname);
	const is_mobile = useMediaQuery("(max-width: 960px)");
	const [is_menu_open, setMenuOpenState] = useState(false);
	const onToggleMenu = useCallback(() => setMenuOpenState(!is_menu_open), [is_menu_open]);

	const is_home = isHome(pathname);
	const home_url = is_logged_in ? "/superpole" : "/";

	useEffect(() => {
		const classList = document.body.classList;
		is_menu_open ? classList.add("is-menu-open") : classList.remove("is-menu-open");
	}, [is_menu_open]);

	useEffect(() => setMenuOpenState(false), [is_mobile]);

	useEffect(() => {
		if (prevPathname && prevPathname !== pathname) {
			setMenuOpenState(false);
		}
	}, [pathname, prevPathname, dispatch]);

	return (
		<React.Fragment>
			<HeaderWrapper is_open={is_menu_open} is_home={is_home}>
				<Container>
					{is_mobile ? (
						<HamburgerMenu onClick={onToggleMenu}>
							<img src={hamburger} alt="Menu" />
						</HamburgerMenu>
					) : (
						<LeftNavigation is_home={is_home} home_url={home_url} />
					)}
					<NavLink to="/" className="logo">
						<img src={logo} alt="WorldSBK Predictor" />
					</NavLink>
					<RightNavigation is_home={is_home} />
				</Container>
			</HeaderWrapper>
			{is_menu_open ? (
				<MobileMenu>
					<Navigation home_url={home_url} />
				</MobileMenu>
			) : null}
		</React.Fragment>
	);
};
