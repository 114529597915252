import styled from 'styled-components';

export const PageWrapper = styled.div`
	max-width: 1240px;
	width: 100%;
	margin: 0 auto;
	box-sizing: border-box;
	
	button, a {
		margin: 0 0 20px 0;
	}

	@media screen and (max-width: 960px) {
		padding: 0 10px;
	}
`;