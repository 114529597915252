import {createReducer} from "redux-act";
import {
	hideCrossPromotionModal,
	showCrossPromotionModal,
	showModalLogin,
	hideModalLogin,
	showModalRegistration,
	hideModalRegistration,
	showModalForgottenPassword,
	hideModalForgottenPassword,
	showModalChangePassword,
	hideModalChangePassword,
} from "modules/actions";
import {CrossPromotionModalType} from "modules/utils";

export interface IModals {
	crossPromotion: CrossPromotionModalType;
	login_modal_visible: boolean;
	registration_modal_visible: boolean;
	forgot_pass_modal_visible: boolean;
	change_pass_modal_visible: boolean;
}

// const isShown = PersistStorage.GET("CrossPromotionHide") === "true";

const defaultState: IModals = {
	crossPromotion: CrossPromotionModalType.Show,
	login_modal_visible: false,
	registration_modal_visible: false,
	forgot_pass_modal_visible: false,
	change_pass_modal_visible: false,
};

export const modals = createReducer<typeof defaultState>({}, defaultState)
	.on(hideCrossPromotionModal, (state) => ({
		...state,
		crossPromotion: CrossPromotionModalType.Hidden,
	}))
	.on(showCrossPromotionModal, (state) => ({
		...state,
		crossPromotion: CrossPromotionModalType.Show,
	}))
	.on(showModalLogin, (state) => ({
		...state,
		login_modal_visible: true,
	}))
	.on(hideModalLogin, (state) => ({
		...state,
		login_modal_visible: false,
	}))

	.on(showModalRegistration, (state) => ({
		...state,
		registration_modal_visible: true,
	}))
	.on(hideModalRegistration, (state) => ({
		...state,
		registration_modal_visible: false,
	}))
	.on(showModalForgottenPassword, (state) => ({
		...state,
		forgot_pass_modal_visible: true,
	}))
	.on(hideModalForgottenPassword, (state) => ({
		...state,
		forgot_pass_modal_visible: false,
	}))
	.on(showModalChangePassword, (state) => ({
		...state,
		change_pass_modal_visible: true,
	}))
	.on(hideModalChangePassword, (state) => ({
		...state,
		change_pass_modal_visible: false,
	}));
