import {all, call, put} from "typed-redux-saga";
import {Api} from "modules/utils";
import * as actions from "modules/actions";
import {IFetchJSONResponse} from "modules/reducers";
import {IError} from "modules/types";

export const fetchChecksumsJSONSaga = function* () {
	try {
		const {data: response} = yield* call(Api.JSON.checksums);
		yield* put(actions.fetchChecksumsJSONSuccess(response));
	} catch (e) {
		yield* put(actions.fetchChecksumsJSONFailed(e as IError));
	}
};

// export const fetchConstructorsJSONSaga = function* () {
// 	try {
// 		const {data: response} = yield* call(Api.JSON.constructors);
// 		ApiError.CHECK(response);
// 		yield* put(actions.fetchConstructorsJSONSuccess(response));
// 	} catch (e) {
// 		yield* put(actions.fetchConstructorsJSONFailed(e));
// 	}
// };

export const fetchEventsJSONSaga = function* () {
	try {
		const {data: response} = yield* call(Api.JSON.events);

		yield* put(actions.fetchEventsJSONSuccess(response));
	} catch (e) {
		yield* put(actions.fetchEventsJSONFailed(e as IError));
	}
};

export const fetchRidersJSONSaga = function* () {
	try {
		const {data: response} = yield* call(Api.JSON.riders);
		yield* put(actions.fetchRidersJSONSuccess(response));
	} catch (e) {
		yield* put(actions.globalError(e as IError));
	}
};
export const fetchHistoricalStatsSaga = function* () {
	try {
		const {data: response} = yield* call(Api.JSON.historical_stats);
		yield* put(actions.fetchHistoricalSuccess(response));
	} catch (e) {
		yield* put(actions.globalError(e as IError));
	}
};

export const fetchFaqSaga = function* () {
	try {
		const {data: response} = yield* call(Api.JSON.faq);
		yield* put(actions.fetchFaqSuccess(response));
	} catch (e) {
		yield* put(actions.fetchFaqFailed(e as IError));
	}
};

export const fetchHelpPagesSaga = function* () {
	try {
		const {data: response} = yield* call(Api.JSON.help_pages);
		yield* put(actions.fetchHelpPagesSuccess(response));
	} catch (e) {
		yield* put(actions.fetchHelpPagesFailed(e as IError));
	}
};

export const fetchCountriesSaga = function* () {
	try {
		const {data: response} = yield* call(Api.JSON.countries);
		yield* put(actions.fetchCountriesSuccess(response));
	} catch (e) {
		yield* put(actions.fetchCountriesFailed(e as IError));
	}
};

export const fetchHelpJSONs = function* () {
	const response = yield* all([
		call(Api.JSON.categories),
		call(Api.JSON.sections),
		call(Api.JSON.articles),
	]);

	const {categories, sections, articles} = {
		categories: response[0].data,
		sections: response[1].data,
		articles: response[2].data,
	};

	yield* put(
		actions.fetchHelpJSONsSuccess({categories, sections, articles} as IFetchJSONResponse)
	);
};
