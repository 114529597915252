import {createAction} from "redux-act";
import {IError, IUser, IUserUpdate} from "modules/types";
import {ReturnFormNames} from "modules/utils/enums";

export interface IUserBackdoorLoginPayload {
	email: string;
}

export const backdoorLogin = createAction<IUserBackdoorLoginPayload>();
export const backdoorLoginSuccess = createAction<IUser>();
export const backdoorLoginFailed = createAction<IError>();

export interface IUserLoginPayload {
	code: string;
	verifier: string;
	country?: string;
}

export const userLogin = createAction<IUserLoginPayload>();
export const userLoginSuccess = createAction<IUser>();
export const userLoginFailed = createAction<IError>();
export const userAskForRegistration = createAction<IUser>();
export const userAskForReturn = createAction();

export const userFetch = createAction();
export const userFetchSuccess = createAction<IUser>();
export const userFetchFailed = createAction<IError>();

export interface IUserReturnPayload {
	[ReturnFormNames.Sponsor]: 1 | 0;
}

export const userReturn = createAction();
export const userReturnSuccess = createAction<IUser>();

export interface IUserRegisterForGamePayload extends IUserReturnPayload {
	terms: 1;
}

export const userRegisterForGame = createAction<IUserReturnPayload>();
export const userRegisterForGameSuccess = createAction<IUser>();

export const userCreate = createAction<FormData>();
export const userCreateSuccess = createAction<IUser>();
export const userCreateFailed = createAction<IError>();

export const userLogout = createAction();
export const userLogoutSuccess = createAction();
export const userLogoutFailed = createAction<IError>();

export const userUpdate = createAction<IUserUpdate>();
export const userUpdateSuccess = createAction<IUser>();
export const userUpdateFailed = createAction<IError>();
export const resetUserUpdateSuccess = createAction();

export const postUserLogout = createAction();
export const postUserLogoutSuccess = createAction();

export const SSOGetToken = createAction();

export type IUserFriendPayload = {
	isFriend: boolean;
	friendId: number;
};

export const postHandleUserFriend = createAction<IUserFriendPayload>();

// No SSO
export interface IPostLoginPayload {
	email: string;
	password: string;
}
export const postLogin = createAction<IPostLoginPayload>();
export const postLoginSuccess = createAction();
export const postLoginFailed = createAction();

export interface IForgotPasswordPayload {
	email: string;
}
export const postForgotPassword = createAction<IForgotPasswordPayload>();
export const postForgotPasswordSuccess = createAction();
export const postForgotPasswordFailed = createAction();

export interface IRestorePasswordPayload {
	password: string;
	token: string;
}
export const postRestorePassword = createAction<IRestorePasswordPayload>();
export const postRestorePasswordSuccess = createAction();
export const postRestorePasswordFailed = createAction<IError>();

export interface ICreateUserPayload {
	email: string;
	nickname: string;
	password: string;
	terms: 1;
	sponsor: 1 | 0;
}
export const postUserCreate = createAction<ICreateUserPayload>();
export const postUserCreateFailed = createAction<IError>();

export const clearUserError = createAction();
export const resetUserStore = createAction();
