import styled from "styled-components";
import React, {useEffect} from "react";
import {isFunction} from "lodash";

export const AdvertRectangleWrapper = styled.div`
	width: 728px;
	height: 100px;
	min-height: 100px;
	margin: 58px auto 70px;
	position: relative;
	overflow: hidden;

	@media screen and (max-width: 1000px) {
		display: none;
	}
`;

export const MobileAdvertWrapper = styled.div`
	width: 365px;
	height: 100px;
	max-width: 100%;
	min-height: 100px;
	margin: 36px auto 35px;
	position: relative;
	overflow: hidden;

	@media screen and (min-width: 1000px) {
		display: none;
	}
`;

const Wrap = styled.div`
	padding: 1px;
	background: #0f0f19;
`;

const Ad: React.FC = () => {
	const desktopID = "wsbk_predictor_728x100";
	const mobileID = "wsbk_predictor_365x100";

	useEffect(() => {
		const GT = window.googletag;

		if (GT && GT.cmd) {
			GT.cmd.push(
				() => GT.display(desktopID),
				() => GT.display(mobileID)
			);

			if (isFunction(GT.pubads)) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				GT.pubads().refresh();
			}
		}
	}, [desktopID, mobileID]);

	return (
		<Wrap>
			<AdvertRectangleWrapper id={desktopID} />
			<MobileAdvertWrapper id={mobileID} />
		</Wrap>
	);
};

export const AdvertRectangle = () => {
	return <Ad />;
};
