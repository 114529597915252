import styled from "styled-components";
import {IInput} from "components";

export const TextArea = styled.textarea<IInput>`
	border: 1px solid ${({has_error}) => (has_error ? "#C80502" : "#d2d2d2")};
	width: 100%;
	max-width: 100%;
	height: 180px;
	padding-left: 10px;
	padding-top: 10px;
	resize: none;
	var(--fontFamilySecondary);
`;
