import { createReducer } from 'redux-act';
import * as actions from 'modules/actions';

export interface ICountry {
	code: string;
	name: string;
}

const initialState: ICountry[] = [];

export const countries = createReducer({}, initialState)
	.on(actions.fetchCountriesSuccess, (_state, payload) => payload)
	.on(actions.fetchCountriesFailed, () => initialState);
