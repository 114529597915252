// Next polyfills are required to made IE11 work.
import "core-js/es";
import "core-js/features/url";
import "core-js/features/url-search-params";

import "assets/css/reset.css";
import "assets/css/fonts.css";
import "assets/css/core.css";

import React, {lazy, Suspense} from "react";
import store from "modules/store";
import {Provider} from "react-redux";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {
	Preloader,
	Content,
	NotAuthOnlyRoute,
	Header,
	Footer,
	ModalError,
	PrivateRoute,
	ModalReturningUser,
	HOCSession,
	HOCAdvert,
	ModalRegisterForGame,
	HOCCrossPromotion,
} from "components";
import {BASE_URL, ENV} from "modules/utils";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {retryFailLoad} from "modules/lazy_load";
import {ModalLogin} from "components/Modal/ModalLogin";
import {ModalRegistration} from "components/Modal/ModalRegistration";
import {ModalForgottenPassword} from "components/Modal/ModalForgottenPassword";
import {ModalRestorePassword} from "components/Modal/ModalRestorePassword";
import {createRoot} from "react-dom/client";

const Predictor = lazy(retryFailLoad(() => import("pages/Predictor")));
const Backdoor = lazy(retryFailLoad(() => import("pages/Backdoor")));
const Help = lazy(retryFailLoad(() => import("pages/Help")));
const MyAccount = lazy(retryFailLoad(() => import("pages/MyAccount")));
const Prizes = lazy(retryFailLoad(() => import("pages/Prizes")));
const NotFound = lazy(retryFailLoad(() => import("pages/NotFound")));
const Logout = lazy(retryFailLoad(() => import("pages/Logout")));
const LeaderBoard = lazy(retryFailLoad(() => import("pages/LeaderBoard")));
const StatsCentre = lazy(retryFailLoad(() => import("pages/StatsCentre")));
const Login = lazy(retryFailLoad(() => import("pages/Login")));

const IS_PRODUCTION = ENV === "production";
Sentry.init({
	dsn: "https://08ab967a8c824c229d0560a8726d5d8a@o151969.ingest.sentry.io/4504490942070784",
	environment: process.env.REACT_APP_SENTRY_ENV || "development",
	sampleRate: 0.1,
	allowUrls: ["predictor.worldsbk.com", ".fanhubmedia.com"],
	integrations: [
		new Integrations.BrowserTracing(),
		new Sentry.Integrations.Breadcrumbs({
			console: false,
		}),
	],
	denyUrls: [
		"quantcast",
		"xsca",
		// browser's extensions
		/extensions\//i,
		/^chrome:\/\//i,
		/^moz-extension:\/\//i,
	],
	autoSessionTracking: true,
});

const root = document.getElementById("root");

if (!root) {
	throw Error("Root element was not found");
}

createRoot(root).render(
	<React.StrictMode>
		<Suspense fallback={<Preloader />}>
			<Provider store={store}>
				<Router basename={BASE_URL}>
					<HOCCrossPromotion>
						<Header />
						<Content>
							{/*<HOCSSO>*/}
							<Suspense fallback={<Preloader />}>
								<HOCSession>
									<HOCAdvert>
										<Switch>
											<Route exact={true} path="/" component={Predictor} />
											<NotAuthOnlyRoute path="/login" component={Login} />
											{IS_PRODUCTION ? null : (
												<NotAuthOnlyRoute
													exact={true}
													path="/backdoor"
													component={Backdoor}
												/>
											)}
											<Route
												exact={true}
												path="/superpole/:event_id?"
												component={Predictor}
											/>
											<Route
												exact={true}
												path="/help/:section?"
												component={Help}
											/>
											<Route exact={true} path="/prizes" component={Prizes} />
											<PrivateRoute
												exact={true}
												path="/leaderboard"
												component={LeaderBoard}
											/>
											<PrivateRoute
												exact={true}
												path="/stats"
												component={StatsCentre}
											/>
											<PrivateRoute
												exact={true}
												path="/account"
												component={MyAccount}
											/>
											<PrivateRoute
												exact={true}
												path="/logout"
												component={Logout}
											/>

											<Route path="*" component={NotFound} />
										</Switch>
									</HOCAdvert>
								</HOCSession>
							</Suspense>
							{/*</HOCSSO>*/}
						</Content>
						<Footer />
						<ModalError />
						<ModalReturningUser />
						<ModalRegisterForGame />
						<ModalLogin />
						<ModalRegistration />
						<ModalForgottenPassword />
						<ModalRestorePassword />
					</HOCCrossPromotion>
				</Router>
			</Provider>
		</Suspense>
	</React.StrictMode>
);
