import React, {useCallback, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {
	getActualEvent,
	getEventsFromFirstToActual,
	getGameBar,
	isLoggedIn,
} from "modules/selectors";
import {ArrowSlim} from "components/Icons";
import {useHistory} from "react-router-dom";
import {
	FLAGS_URL,
	useMediaQuery,
	usePrevious,
	useSelectedEvent,
	useStartEndDate,
} from "modules/utils";
import moment from "moment";
import {isEqual} from "lodash";
import {IEvent} from "modules/types";

const date_format = "D MMM YY";

const Nav = styled.div`
	width: 100%;
	background: #0f0f19;
	height: 80px;
	color: #fff;
	position: relative;
	z-index: 10;

	@media screen and (max-width: 960px) {
		height: 160px;
		&.logged-out {
			height: 80px;
		}
	}
`;

const Events = styled.div`
	max-width: 1200px;
	display: flex;
	margin: 0 auto;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	overflow: hidden;
	.hidden {
		display: none;
	}
`;

const NavItem = styled.button`
	background: none;
	outline: none;
	height: 100%;
	width: 50px;
	border: none;
	cursor: pointer;
	&:disabled {
		cursor: default;
	}
	background: #0f0f19;
`;
const SliderContainer = styled.ul<{width?: string}>`
	display: flex;
	flex-wrap: nowrap;
	height: 80px;
	width: ${({width}) => width || "100%"};
	@media screen and (max-width: 960px) {
		height: 160px;
		&.logged-out {
			height: 80px;
		}
	}
`;

const ListItem = styled.li`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	background: #0f0f19;
	position: relative;

	@media screen and (max-width: 960px) {
		flex-direction: column;
	}
`;

const Section = styled.div`
	height: 100%;
	border-right: 1px solid #2b2b2b;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	@media screen and (max-width: 960px) {
		height: 80px;
	}
`;

const FlagSection = styled(Section)`
	var(--fontFamilySecondary);
	font-weight: 700;
	font-size: 16px;
	padding: 0 20px;
	&.logged-out {
		border: none;
	}

	p:first-child {
		font-family: var(--fontFamilyBase);
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 118%;
		letter-spacing: 0.02em;
		color: #ffffff;
		display: flex;
		align-items: center;
		margin-bottom: 5px;
	}

	p:last-child {
		font-family: var(--fontFamilyBase);
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 118%;
		letter-spacing: 0.01em;
		color: #ffffff;
	}

	img {
		height: 33px;
		margin-right: 10px;
	}

	@media screen and (max-width: 960px) {
		border: none;
	}
`;

const SliderWrapper = styled.div`
	width: 100%;
	overflow: hidden;
`;

const StatsSection = styled(Section)`
	var(--fontFamilySecondary);
	text-align: center;
	height: 100%;
	padding: 0 20px;
	.label {
		font-family: var(--fontFamilyBase);
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 118%;
		text-align: center;
		letter-spacing: 0.02em;
		color: #ffffff;
		@media screen and (max-width: 960px) {
			font-size: 14px;
		}
	}
	.value {
		font-family: var(--fontFamilyBase);
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 118%;
		text-align: center;
		letter-spacing: 0.02em;
		color: var(--primaryColor);
		@media screen and (max-width: 960px) {
			font-size: 14px;
		}
	}
	&:last-child {
		border-right: none;
	}
	@media screen and (max-width: 960px) {
		border: 1px solid #2b2b2b;
		&:first-child {
			border-left: none;
		}
	}
`;

const MobileGameBar = styled.div`
	display: flex;
	height: 80px;
`;

const ACTIVE_COLOR = "#ffffff";
const DISABLED_COLOR = "#464646";

const Item: React.FC<{short_name: string; event_id: number}> = ({short_name, event_id}) => {
	const selected_event = useSelectedEvent();
	const game_bar = useSelector(getGameBar);
	const event_dates = useStartEndDate();
	const link = `${FLAGS_URL}${short_name}.png`;
	const is_mobile = useMediaQuery("(max-width: 960px)");

	if (is_mobile) {
		return (
			<ListItem>
				<FlagSection>
					<p>
						<img src={link} alt={selected_event?.name} /> Round {event_id}
					</p>
					<p>
						{event_dates} | {selected_event?.name}
					</p>
				</FlagSection>
				<MobileGameBar>
					<StatsSection>
						<p className="value">{moment(selected_event?.start).format(date_format)}</p>
						<p className="label">Date</p>
					</StatsSection>
					<StatsSection>
						<p className="value">{game_bar.eventPoints ?? "-"}</p>
						<p className="label">Last Pts</p>
					</StatsSection>
					<StatsSection>
						<p className="value">{game_bar.overallPoints ?? "-"}</p>
						<p className="label">Total Pts</p>
					</StatsSection>
				</MobileGameBar>
			</ListItem>
		);
	}

	return (
		<ListItem>
			<FlagSection>
				<p>
					<img src={link} alt={selected_event?.name} /> Round {event_id}
				</p>
				<p>
					{event_dates} | {selected_event?.name}
				</p>
			</FlagSection>
			<StatsSection>
				<p className="value">{moment(selected_event?.start).format(date_format)}</p>
				<p className="label">Date</p>
			</StatsSection>
			<StatsSection>
				<p className="value">{game_bar.eventPoints ?? "-"}</p>
				<p className="label">Last Pts</p>
			</StatsSection>
			<StatsSection>
				<p className="value">{game_bar.overallPoints ?? "-"}</p>
				<p className="label">Total Pts</p>
			</StatsSection>
		</ListItem>
	);
};

const getColor = (is_disabled: boolean) => (is_disabled ? DISABLED_COLOR : ACTIVE_COLOR);

interface IProps {
	selected_event: IEvent;
}

export const LoggedInEventNavigation: React.FC<IProps> = ({selected_event}) => {
	const history = useHistory();
	const last_event = useSelector(getActualEvent);
	console.log(last_event);
	const events = useSelector(getEventsFromFirstToActual);
	const is_prev_disabled = isEqual(selected_event?.position, events[0]?.position);
	const is_next_disabled = isEqual(selected_event?.position, last_event?.position);
	const [slider_width, setSliderWidth] = useState(`${events.length * 100}%`);
	const prev_color = getColor(is_prev_disabled);
	const next_color = getColor(is_next_disabled);
	const prev_event = usePrevious(selected_event);

	const slider_wrap_ref = useRef<HTMLDivElement>(null);
	const slider_ref = useRef<HTMLUListElement>(null);

	const moveSlider = useCallback(
		(mod: number, add = 1) => {
			if (!slider_ref.current) {
				return;
			}
			const width = slider_wrap_ref?.current?.clientWidth || 0;
			const new_translate = mod * width * add;
			slider_ref.current.style.transform = `translate(${new_translate}px)`;
		},
		[slider_ref]
	);

	const changeSelectedEvent = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		if (!selected_event) {
			return;
		}

		const {side} = e.currentTarget.dataset;
		const mod = side === "prev" ? 1 : -1;
		history.push(`/superpole/${selected_event.position - mod}`);
	};

	useEffect(() => {
		if (slider_width === "0%" && selected_event) {
			setSliderWidth(`${events.length * 100}%`);
			moveSlider(-1, selected_event.position - 1);
		}
	}, [events, slider_width, moveSlider, selected_event]);

	useEffect(() => {
		const is_no_prev_event = !prev_event && selected_event;
		const is_diff_event =
			prev_event && selected_event && selected_event.position !== prev_event.position;

		if (is_no_prev_event || is_diff_event) {
			moveSlider(-1, selected_event.position - 1);
		}
	}, [prev_event, selected_event, moveSlider]);

	return (
		<Nav>
			<Events>
				<NavItem
					aria-label={"Previous event"}
					onClick={changeSelectedEvent}
					data-side={"prev"}
					disabled={is_prev_disabled}>
					<ArrowSlim to={"left"} color={prev_color} />
				</NavItem>
				<SliderWrapper ref={slider_wrap_ref}>
					<SliderContainer
						ref={slider_ref}
						width={slider_width}
						className={`active-${selected_event.position}`}>
						{events.map((event, index) => (
							<Item
								event_id={event.position}
								short_name={event.shortname}
								key={index}
							/>
						))}
					</SliderContainer>
				</SliderWrapper>
				<NavItem
					aria-label={"Next event"}
					onClick={changeSelectedEvent}
					data-side={"next"}
					disabled={is_next_disabled}>
					<ArrowSlim to={"right"} color={next_color} />
				</NavItem>
			</Events>
		</Nav>
	);
};

const LoggedOutEventNavigation: React.FC<IProps> = ({selected_event}) => {
	const {shortname, name, position} = selected_event;
	const event_dates = useStartEndDate();
	const link = `${FLAGS_URL}${shortname || ""}.png`;
	return (
		<Nav className="logged-out">
			<Events>
				<SliderWrapper>
					<SliderContainer className="logged-out">
						<ListItem>
							<FlagSection className="logged-out">
								<p>
									<img src={link} alt={name} /> Round {position}
								</p>
								<p>
									{event_dates} | {name}
								</p>
							</FlagSection>
						</ListItem>
					</SliderContainer>
				</SliderWrapper>
			</Events>
		</Nav>
	);
};

export const EventsNavigation: React.FC = () => {
	const is_logged_in = useSelector(isLoggedIn);
	const selected_event = useSelectedEvent();

	if (!selected_event) {
		return null;
	}

	return is_logged_in ? (
		<LoggedInEventNavigation selected_event={selected_event} />
	) : (
		<LoggedOutEventNavigation selected_event={selected_event} />
	);
};
