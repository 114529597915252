import React, {useCallback, useEffect, useState} from "react";
import {
	CloseModalButton,
	ModalDescription,
	ModalInner,
	ModalOverlay,
	ModalTitle,
} from "components/Modal";
import {ButtonPrimary, PasswordField} from "components/FormElements";
import {useDispatch, useSelector} from "react-redux";
import {
	isRestorePassRequestSuccess,
	isChangePasswordFormVisible,
	getUserError,
} from "modules/selectors";
import styled from "styled-components";
import {FormControl} from "@mui/material";
import {IconClose} from "components/Icons";
import {
	hideModalChangePassword,
	postRestorePassword,
	resetUserStore,
	showModalChangePassword,
	showModalLogin,
} from "modules/actions";
import ErrorIcon from "@mui/icons-material/Error";
import {Exist} from "components/Exist";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {useLocation} from "react-router";
import {useHistory} from "react-router-dom";

const FormControlStyled = styled(FormControl)``;

const Details = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	.icon {
		color: #f44336;
		font-size: 42px;
		margin-top: 20px;

		&.icon-check {
			color: #000;
		}
	}

	${ModalTitle} {
		margin: 20px auto;
	}

	form {
		width: 440px;
		max-width: 100%;
		display: flex;
		flex-direction: column;
		* > {
			box-sizing: border-box;
		}
	}

	${FormControlStyled} {
		width: 100%;
		margin-bottom: 20px;
	}

	${ButtonPrimary} {
		width: 100%;
		max-width: 100%;
	}
`;

const ErrorText = styled.p`
	font-family: var(--fontFamilySecondary);
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 140%;
	text-align: center;
	margin-bottom: 20px;
	color: var(--primaryColor);
`;

export const ModalRestorePassword: React.FC = () => {
	const isVisible = useSelector(isChangePasswordFormVisible);
	const [form, changeForm] = useState({password: "", confirm_password: ""});
	const [formValidity, setIsFormValid] = useState({password: false, confirm_password: ""});
	const dispatch = useDispatch();
	const isSuccess = useSelector(isRestorePassRequestSuccess);
	const [isDisabled, setIsDisabled] = useState(true);
	const search = useLocation().search;
	const history = useHistory();
	const token = new URLSearchParams(search).get("token");
	const error = useSelector(getUserError);

	useEffect(() => {
		dispatch(resetUserStore());
	}, [dispatch]);

	useEffect(() => {
		if (isSuccess) {
			history.replace({
				search: "",
			});
		}
	}, [isSuccess, history]);

	useEffect(() => {
		if (token) {
			dispatch(showModalChangePassword());
		}
	}, [token, dispatch]);

	const handleForm = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const {value, name, validity} = e.currentTarget;

			changeForm({
				...form,
				[name]: value,
			});
			setIsFormValid({
				...formValidity,
				[name]: validity.valid,
			});

			if (error) {
				dispatch(resetUserStore());
			}
		},
		[form, formValidity, error, dispatch]
	);

	useEffect(() => {
		setIsDisabled(!formValidity.password || form.password !== form.confirm_password);
	}, [form, formValidity]);

	const submitForm = useCallback(
		(e: React.SyntheticEvent<HTMLFormElement>) => {
			e.preventDefault();
			e.stopPropagation();

			if (token) {
				dispatch(
					postRestorePassword({
						password: form.password,
						token,
					})
				);
			}
		},
		[dispatch, form, token]
	);

	const closeModal = () => {
		dispatch(hideModalChangePassword());
		dispatch(resetUserStore());
	};

	const showLogin = () => {
		dispatch(hideModalChangePassword());
		dispatch(showModalLogin());
	};

	if (!isVisible) {
		return null;
	}

	return (
		<ModalOverlay>
			<ModalInner>
				<CloseModalButton onClick={closeModal}>
					<IconClose />
				</CloseModalButton>
				<Exist when={isSuccess}>
					<Details>
						<CheckCircleIcon className="icon icon-check" />
						<ModalTitle>Password changed</ModalTitle>
						<ButtonPrimary onClick={showLogin}>Show Log in</ButtonPrimary>
					</Details>
				</Exist>
				<Exist when={!isSuccess}>
					<Details>
						<ErrorIcon className="icon" />

						<ModalTitle>Change Password</ModalTitle>

						<ModalDescription>
							Change your password by filling the fields below
						</ModalDescription>

						<Exist when={Boolean(error?.message)}>
							<ErrorText>{error?.message}</ErrorText>
						</Exist>

						<form onSubmit={submitForm}>
							<PasswordField onChange={handleForm} name="password" id="password" />
							<PasswordField
								onChange={handleForm}
								label="Confirm Password"
								name="confirm_password"
								id="confirm-password"
							/>
							<ButtonPrimary disabled={isDisabled}>Submit</ButtonPrimary>
						</form>
					</Details>
				</Exist>
			</ModalInner>
		</ModalOverlay>
	);
};
