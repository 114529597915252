import React, {useCallback, useEffect, useState} from "react";
import {ModalOverlay} from "components/Modal";
import styled from "styled-components";
import {ButtonPrimary, CheckBox, FormGroup} from "components/FormElements";
import {useDispatch, useSelector} from "react-redux";
import {userRegisterForGame} from "modules/actions";
import {ReturnFormNames} from "modules/utils";
import {isNeedToRegisterForGame} from "modules/selectors";
import {Link} from "react-router-dom";
import logo from "assets/img/logo-dark.svg";

const Modal = styled.div`
	background: #fff;
	width: 100%;
	max-width: 480px;
	box-sizing: border-box;
	max-height: 100%;
	max-height: 100vh;
	position: relative;
	overflow: auto;

	button {
		margin-top: 10px;
		margin-left: 0;
	}
`;
const ModalHeader = styled.header`
	height: 95px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	border-bottom: 1px solid #d8d8d8;
`;
const ModalInner = styled.div`
	padding: 20px 40px 30px;
`;

const Text = styled.p`
	color: #000000;
	var(--fontFamilySecondary);
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0;
	line-height: 20px;
	margin-bottom: 20px;
`;

export const ModalRegisterForGame: React.FC = () => {
	const dispatch = useDispatch();
	const is_returning_user = useSelector(isNeedToRegisterForGame);
	const [values, updateValue] = useState({
		[ReturnFormNames.Sponsor]: false,
		[ReturnFormNames.Terms]: false,
	});
	const [isDisabled, handleSubmitButton] = useState(true);

	const handleChange = useCallback(
		(event: React.SyntheticEvent<HTMLInputElement>) => {
			const {name, checked} = event.currentTarget;
			updateValue({
				...values,
				[name]: checked,
			});
		},
		[values]
	);

	const onSubmit = useCallback(
		(event: React.SyntheticEvent<HTMLFormElement>) => {
			event.preventDefault();
			event.stopPropagation();

			if (!event.currentTarget.checkValidity()) {
				return;
			}
			dispatch(
				userRegisterForGame({
					[ReturnFormNames.Sponsor]: values[ReturnFormNames.Sponsor] ? 1 : 0,
				})
			);
		},
		[dispatch, values]
	);

	useEffect(() => {
		handleSubmitButton(!values[ReturnFormNames.Terms]);
	}, [values]);

	if (!is_returning_user) {
		return null;
	}
	return (
		<ModalOverlay>
			<Modal>
				<ModalHeader>
					<img src={logo} alt="MotoGP™ Predictor" />
				</ModalHeader>
				<ModalInner>
					<Text>
						Welcome to MotoGP™ Predictor, presented by Tissot! Because you already have
						a MotoGP™ registered account, all you need to start playing is to accept the
						Terms & Conditions
					</Text>
					<form onSubmit={onSubmit}>
						<FormGroup>
							<CheckBox
								name={ReturnFormNames.Sponsor}
								title="Opt-in for Motul's emails to get a chance to win a Motul MC Care Package!"
								tick_position="left"
								required={false}
								onChange={handleChange}>
								I agree to participate at the Predictor GP Reward Contest (organized
								by Tissot SA) by accepting these{" "}
								<a
									href="https://www.tissotwatches.com/media/downloadable/int/tc_tissot_contest_motogp_predictor_2022.pdf"
									rel="noopener noreferrer"
									target="_blank">
									T&Cs
								</a>
								. I accept, as well, that DORNA communicates my personal data to
								Tissot SA for processing my participation in the contest and for
								sending me the TISSOT newsletter. I acknowledge and accept that
								Tissot SA and its affiliate in my country will process my data for
								this purpose. I confirm to have read and understood the{" "}
								<a
									href="https://www.tissotwatches.com/en-en/privacy-notice.html"
									target="_blank"
									rel="noopener noreferrer">
									Privacy Notice
								</a>
							</CheckBox>
						</FormGroup>
						<FormGroup>
							<CheckBox
								name={ReturnFormNames.Terms}
								title="Accepting the Terms & Conditions"
								tick_position="left"
								required={true}
								onChange={handleChange}>
								I have read and accept the{" "}
								<Link to="/help/rules" target="_blank">
									legal conditions
								</Link>{" "}
								of the Predictor MotoGPGame, confirming that I am over 16 years old
							</CheckBox>
						</FormGroup>
						<ButtonPrimary disabled={isDisabled} id="register-button" type="submit">
							Start Predicting
						</ButtonPrimary>
					</form>
				</ModalInner>
			</Modal>
		</ModalOverlay>
	);
};
