import {createSelector} from "reselect";
import {IStore, IUser} from "modules/types";
import {get} from "lodash";
import {IS_LOCALHOST} from "modules/utils/Constant";
import {Storage} from "modules/utils/Storage";
import {RequestStateType} from "modules/utils";

export const getUserState = ({user}: IStore) => user;

export const isLoggedIn = createSelector(getUserState, (state) => {
	const isBackdoorLoggedIn = Boolean(IS_LOCALHOST && Storage.GET("sid"));
	return isBackdoorLoggedIn || state.is_authenticated;
});

export const isSessionChecked = createSelector(getUserState, (state) => {
	const isBackdoorLoggedIn = Boolean(IS_LOCALHOST && Storage.GET("sid"));
	return isBackdoorLoggedIn || state.is_session_checked;
});

export const getUserObject = createSelector(getUserState, (state) => state.user as IUser);

export const getUserId = createSelector(getUserObject, (user) => get(user, "id"));

export const isAdditionalDataRequired = createSelector(getUserState, (state) =>
	Boolean(state.is_additional_data_required)
);

export const isReturningUser = createSelector(
	getUserState,
	(state) => state.is_return_data_required
);
export const isNeedToRegisterForGame = createSelector(
	getUserState,
	(state) => state.is_need_to_register_for_game
);

export const canFetchData = createSelector(
	isLoggedIn,
	isReturningUser,
	isNeedToRegisterForGame,
	(is_logged_in, is_need_register_user, is_returning_user) => {
		return is_logged_in && !is_need_register_user && !is_returning_user;
	}
);

export const isUserUpdated = createSelector(getUserState, (state) => state.user_updated);

export const isDataRequested = createSelector(getUserState, (state) => state.is_data_requested);

export const getRegistrationProcessState = createSelector(
	getUserState,
	(state) => state.registration_request
);
export const isForgotPassRequestSuccess = createSelector(
	getUserState,
	(state) => state.forgot_password_request === RequestStateType.Received
);
export const isRestorePassRequestSuccess = createSelector(
	getUserState,
	(state) => state.restore_password_request === RequestStateType.Received
);

export const getUserError = createSelector(getUserState, (state) => state.error);

export const isLogInSuccess = createSelector(
	getUserState,
	(state) => state.login_request === RequestStateType.Received
);
