import {createReducer} from "redux-act";
import {
	closeInfoBlock,
	fetchFaqSuccess,
	fetchHelpJSONsSuccess,
	fetchHelpPagesSuccess,
} from "modules/actions";
import {Storage} from "modules/utils";

export interface IFetchJSONResponse {
	categories: IHelpCategory;
	sections: IHelpSection;
	articles: IHelpArticle;
}
export interface IHelpCategory {
	categories: ICategory[];
	page: number;
	previous_page: null;
	next_page: null;
	per_page: number;
	page_count: number;
	count: number;
	sort_by: string;
	sort_order: string;
}

export interface ICategory {
	id: number;
	url: string;
	html_url: string;
	position: number;
	created_at: Date;
	updated_at: Date;
	name: string;
	description: string;
	locale: string;
	source_locale: string;
	outdated: boolean;
}

export interface IHelpSection {
	sections: ISection[];
	page: number;
	previous_page: null;
	next_page: null;
	per_page: number;
	page_count: number;
	count: number;
	sort_by: string;
	sort_order: string;
}

export interface ISection {
	id: number;
	url: string;
	html_url: string;
	category_id: number;
	position: number;
	sorting: string;
	created_at: Date;
	updated_at: Date;
	name: string;
	description: string;
	locale: string;
	source_locale: string;
	outdated: boolean;
	parent_section_id: null;
	theme_template: string;
}

export interface IHelpArticle {
	count: number;
	next_page: null;
	page: number;
	page_count: number;
	per_page: number;
	previous_page: null;
	articles: IArticle[];
	sort_by: string;
	sort_order: string;
}

export interface IArticle {
	id: number;
	url: string;
	html_url: string;
	author_id: number;
	comments_disabled: boolean;
	draft: boolean;
	promoted: boolean;
	position: number;
	vote_sum: number;
	vote_count: number;
	section_id: number;
	created_at: Date;
	updated_at: Date;
	name: string;
	title: string;
	source_locale: string;
	locale: string;
	outdated: boolean;
	outdated_locales: unknown[];
	edited_at: Date;
	user_segment_id: null;
	permission_group_id: number;
	label_names: unknown[];
	body: string;
}

export interface IFaqItem {
	title: string;
	text: string;
}

export interface IHelpPage extends IFaqItem {
	type: string;
}

export interface IHelpPages {
	howtoplay: IHelpPage;
	prizes: IHelpPage;
	terms: IHelpPage;
}

export interface IHelpsReducer {
	help_pages: IHelpPages | Record<string, unknown>;
	faq: IFaqItem[];
	info: boolean;

	help_content: null | IFetchJSONResponse;
}

const initialState: IHelpsReducer = {
	help_pages: {},
	faq: [],
	info: JSON.parse(Storage.GET("info") || "true") as boolean,
	help_content: null,
};

export const help = createReducer<IHelpsReducer>({}, initialState)
	.on(fetchHelpPagesSuccess, (state, result) => ({
		...state,
		help_pages: result,
	}))
	.on(fetchFaqSuccess, (state, result) => ({
		...state,
		faq: result,
	}))
	.on(closeInfoBlock, (state) => {
		Storage.SET("info", "false");

		return {
			...state,
			info: false,
		};
	})
	.on(fetchHelpJSONsSuccess, (state, payload) => ({
		...state,
		help_content: payload,
	}));
