import {identity} from "lodash";
import {IShare} from "modules/types";
import {ShareNet} from "modules/utils/enums";

const FB_SHARE_URL = process.env.REACT_APP_FB_SHARE_URL || "";

/**
 * in order to reduce the size of the string for sharing
 */
const last_four_number = -4;
const getTimestamp = () => Date.now().toString().substr(last_four_number);

interface IShareObject {
	t: string;
	sc: string;
	predictionId: number | null;
}
const getEncodedJson = (object: IShareObject) => btoa(JSON.stringify(object));
const getLinkForShare = (object: IShareObject) => {
	return `${FB_SHARE_URL}en/predictor_prediction/${getEncodedJson(object)}`;
};

export const share = (data: IShare) => {
	if (data.sc === ShareNet.Twitter) {
		shareTwitter(data);
	}

	if (data.sc === ShareNet.Facebook) {
		shareFB(data);
	}
	if (data.sc === ShareNet.WhatsApp) {
		shareWhatsApp(data);
	}
};

export const shareFB = (data: IShare) => {
	const FB_API = window.FB;
	const {sc, predictionId} = data;
	const object: IShareObject = {
		sc,
		predictionId: predictionId,
		t: getTimestamp(),
	};

	FB_API.ui(
		{
			method: "share",
			display: "popup",
			href: getLinkForShare(object),
		},
		identity
	);
};

export const shareTwitter = (data: IShare) => {
	const {sc, predictionId, message = ""} = data;
	const object: IShareObject = {
		sc,
		predictionId: predictionId,
		t: getTimestamp(),
	};

	const href = getLinkForShare(object);

	window.open(
		"https://twitter.com/share?url=" +
			encodeURIComponent(href) +
			"&text=" +
			encodeURIComponent(message),
		"twitter-share-dialog",
		"width=626,height=436"
	);
};

export const shareWhatsApp = (data: IShare) => {
	const {sc, predictionId, message = ""} = data;
	const object: IShareObject = {
		sc,
		predictionId: predictionId,
		t: getTimestamp(),
	};

	const href = getLinkForShare(object);

	return {
		title: message,
		url: href,
	};
};
