import React, {useCallback, useEffect, useState} from "react";
import {CloseModalButton, ModalInner, ModalOverlay, ModalTitle} from "components/Modal";
import {ReactComponent as Logo} from "assets/img/logo-dark.svg";
import {
	PasswordField,
	FieldText,
	ButtonPrimary,
	PureButton,
	CheckBox,
	FormGroup,
} from "components/FormElements";
import {useDispatch, useSelector} from "react-redux";
import {
	getRegistrationProcessState,
	getUserError,
	isRegistrationFormVisible,
} from "modules/selectors";
import styled from "styled-components";
import {FormControl, InputLabel} from "@mui/material";
import {IconClose} from "components/Icons";
import {
	hideModalRegistration,
	postUserCreate,
	resetUserStore,
	showModalLogin,
} from "modules/actions";
import {every} from "lodash";
import {RequestStateType} from "modules/utils";
import {Exist} from "components/Exist";
import {Link} from "react-router-dom";

const FormControlStyled = styled(FormControl)``;

const Details = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	${ModalTitle} {
		margin: 20px auto;
	}

	form {
		width: 440px;
		max-width: 100%;
		display: flex;
		flex-direction: column;

		* > {
			box-sizing: border-box;
		}
	}

	${FormControlStyled} {
		width: 100%;
		margin-bottom: 20px;
	}

	${ButtonPrimary} {
		width: 100%;
		max-width: 100%;
	}
`;

const FormFooter = styled.p`
	text-align: center;
	font-family: var(--fontFamilySecondary);
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 140%;
	margin: 20px auto;

	${PureButton} {
		color: var(--primaryColor);
		transition: all 250ms ease-in-out;
		font-family: var(--fontFamilySecondary);
		font-weight: 500;
		font-size: 14px;

		&:hover {
			color: #aa0000;
		}
	}
`;

const Disclaimer = styled.p`
	font-family: var(--fontFamilySecondary);
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 140%;
	text-align: center;
	color: #000;
	margin-bottom: 20px;

	a {
		color: var(--primaryColor);
		&:hover {
			color: #aa0000;
		}
	}
`;

const ErrorText = styled(Disclaimer)`
	color: var(--primaryColor);
`;

export const ModalRegistration: React.FC = () => {
	const isVisible = useSelector(isRegistrationFormVisible);
	const [form, changeForm] = useState({
		nickname: "",
		email: "",
		confirm_email: "",
		password: "",
		confirm_password: "",
		terms: "",
		sponsor: "",
	});
	const [formValidity, setIsFormValid] = useState({
		email: false,
		confirm_email: false,
		password: false,
		confirm_password: false,
		terms: false,
	});
	const dispatch = useDispatch();
	const [isDisabled, setIsDisabled] = useState(true);
	const processState = useSelector(getRegistrationProcessState);
	const error = useSelector(getUserError);
	const [emailError, setEmailError] = useState(false);
	const [passwordErrors, setPasswordError] = useState({
		password: false,
		confirm_password: false,
		equal: false,
	});

	useEffect(() => {
		dispatch(resetUserStore());
	}, [dispatch]);

	useEffect(() => {
		const isFormValid = every(formValidity);
		const isEmailSame = form.email === form.confirm_email;
		const isPasswordSame = form.password === form.confirm_password;
		const isError = Boolean(error);

		setIsDisabled(isError || !(isFormValid && isEmailSame && isPasswordSame));
	}, [form, formValidity, error]);

	const handleForm = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const {value, name, validity, checked} = e.currentTarget;
			const result = ["sponsor", "terms"].includes(name) ? checked : value;

			changeForm({
				...form,
				[name]: result,
			});
			setIsFormValid({
				...formValidity,
				[name]: validity.valid,
			});
			dispatch(resetUserStore());
		},
		[form, formValidity, dispatch]
	);

	const submitForm = useCallback(
		(e: React.SyntheticEvent<HTMLFormElement>) => {
			e.preventDefault();
			e.stopPropagation();

			const {email, nickname, password, sponsor} = form;
			if (processState === RequestStateType.IDLE) {
				dispatch(
					postUserCreate({
						email,
						nickname,
						password,
						terms: 1,
						sponsor: sponsor ? 1 : 0,
					})
				);
			}
		},
		[dispatch, form, processState]
	);

	const closeModal = useCallback(() => {
		dispatch(hideModalRegistration());
		dispatch(resetUserStore());
	}, [dispatch]);

	const handleLoginModal = () => {
		closeModal();
		dispatch(showModalLogin());
	};

	const onEmailBlur = () => {
		const isEmailSame = form.email === form.confirm_email;
		setEmailError(Boolean(form.email && form.confirm_email && !isEmailSame));
	};

	const onPasswordBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		const isPasswordSame = form.password === form.confirm_password;
		const {name, validity} = e.currentTarget;
		const isNotInPattern = validity.patternMismatch;

		setPasswordError({
			...passwordErrors,
			[name]: isNotInPattern,
			equal: Boolean(form.password && form.confirm_password && !isPasswordSame),
		});
	};

	useEffect(() => {
		if (processState === RequestStateType.Received) {
			closeModal();
		}
	}, [processState, closeModal]);

	if (!isVisible) {
		return null;
	}

	return (
		<ModalOverlay>
			<ModalInner>
				<CloseModalButton onClick={closeModal}>
					<IconClose />
				</CloseModalButton>
				<Details>
					<Logo />

					<ModalTitle>Register an account</ModalTitle>

					<Exist when={Boolean(error?.message)}>
						<ErrorText>{error?.message}</ErrorText>
					</Exist>

					<form onSubmit={submitForm}>
						<FormControlStyled>
							<InputLabel htmlFor="nickname">Display Name</InputLabel>
							<FieldText
								autoComplete="off"
								onChange={handleForm}
								label="Display Name"
								name="nickname"
								type="text"
								id="nickname"
							/>
						</FormControlStyled>

						<FormControlStyled>
							<InputLabel htmlFor="email">Email Address</InputLabel>
							<FieldText
								onChange={handleForm}
								label="Email Address"
								name="email"
								type="email"
								id="email"
								onBlur={onEmailBlur}
							/>
						</FormControlStyled>
						<FormControlStyled>
							<InputLabel htmlFor="confirm-email">Confirm Email Address</InputLabel>
							<FieldText
								onChange={handleForm}
								label="Confirm Email Address"
								name="confirm_email"
								type="email"
								id="confirm-email"
								onBlur={onEmailBlur}
							/>
						</FormControlStyled>

						<Exist when={emailError}>
							<ErrorText>Email addresses do not match</ErrorText>
						</Exist>

						<PasswordField
							onBlur={onPasswordBlur}
							onChange={handleForm}
							name="password"
							id="password"
						/>
						<Exist when={passwordErrors.password}>
							<ErrorText>
								Password must include: Min. 8 characters, 1 lowercase, 1 uppercase
								and 1 number. ONLY the following special characters are allowed:
								!@#$%^
							</ErrorText>
						</Exist>
						<PasswordField
							onChange={handleForm}
							onBlur={onPasswordBlur}
							label="Confirm Password"
							name="confirm_password"
							id="confirm-password"
						/>
						<Exist when={passwordErrors.confirm_password}>
							<ErrorText>
								Password must include: Min. 8 characters, 1 lowercase, 1 uppercase
								and 1 number. ONLY the following special characters are allowed:
								!@#$%^
							</ErrorText>
						</Exist>
						<Exist when={passwordErrors.equal}>
							<ErrorText>Passwords do not match</ErrorText>
						</Exist>

						<FormGroup>
							<CheckBox
								name="terms"
								title=""
								tick_position="left"
								required={true}
								onChange={handleForm}>
								I have read and accept the{" "}
								<Link
									target="_blank"
									rel="noopener noreferrer"
									to="help/terms-and-conditions">
									Terms & Conditions
								</Link>{" "}
								of the Predictor WSBKGame, confirming that I am over 16 years old.
								DORNA SPORTS, S.L. will process your personal data in accordance
								with this{" "}
								<Link
									target="_blank"
									rel="noopener noreferrer"
									to="help/privacy-policy">
									Privacy Policy
								</Link>
								. You are able to exercise your rights at all times contacting{" "}
								<a
									href="mailto:dpo@dorna.com"
									target="_blank"
									rel="noopener noreferrer">
									dpo@dorna.com
								</a>
							</CheckBox>
						</FormGroup>

						<FormGroup>
							<CheckBox
								name="sponsor"
								title=""
								tick_position="left"
								onChange={handleForm}>
								I agree to participate at the Predictor GP Reward Contest (organized
								by Tissot SA) by accepting these{" "}
								<a
									target="_blank"
									rel="noopener noreferrer"
									href="https://www.tissotwatches.com/media/downloadable/T_C_tissot_contest_sbk_predictor_2024.pdf">
									T&Cs
								</a>
								. I accept, as well, that DORNA communicates my personal data to
								Tissot SA for processing my participation in the contest and for
								sending me the TISSOT newsletter. I acknowledge and accept that
								Tissot SA and its affiliate in my country will process my data for
								this purpose. I confirm to have read and understood the{" "}
								<a
									href="https://www.tissotwatches.com/en-gb/privacy-notice.html"
									target="_blank"
									rel="noopener noreferrer">
									Privacy Notice
								</a>
							</CheckBox>
						</FormGroup>

						<Disclaimer>By clicking, I confirm I’m at least 16-years-old</Disclaimer>

						<ButtonPrimary disabled={isDisabled}>Register</ButtonPrimary>

						<FormFooter>
							Already have account?{" "}
							<PureButton onClick={handleLoginModal}>Log in</PureButton>
						</FormFooter>

						<Disclaimer>
							When becoming a WSBK Predictor user, DORNA SPORTS, S.L. will process
							your personal data in accordance with this Privacy Policy. You are able
							to exercise your rights at all times contacting DORNA directly by post
							or (iii) by sending an e-mail to{" "}
							<a
								href="mailto:dpo@dorna.com"
								target="_blank"
								rel="noopener noreferrer">
								dpo@dorna.com
							</a>
						</Disclaimer>
					</form>
				</Details>
			</ModalInner>
		</ModalOverlay>
	);
};
