import React, {Fragment} from "react";
import {ITimeResult} from "modules/types";
import {getTimeInputLengths} from "modules/selectors";
import {useSelector} from "react-redux";
import {padStart} from "lodash";

interface IProps {
	time_result: ITimeResult;
	with_pad?: boolean;
}

export const TimeResults: React.FC<IProps> = ({time_result, with_pad}) => {
	const input_length = useSelector(getTimeInputLengths);

	if (!time_result) {
		return null;
	}
	const {minutes, seconds, milliseconds} = time_result;

	if (with_pad) {
		const seconds_str = padStart(seconds?.toString(), input_length.seconds, "0");
		const milliseconds_str = padStart(milliseconds?.toString(), input_length.milliseconds, "0");
		return (
			<Fragment>
				{minutes}:{seconds_str}:{milliseconds_str}
			</Fragment>
		);
	}

	return (
		<Fragment>
			{minutes}:{seconds}:{milliseconds}
		</Fragment>
	);
};
