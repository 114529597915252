import {createReducer} from "redux-act";
import {fetchHistoricalSuccess} from "modules/actions";
import {IHistoricalStats} from "modules/types";

const defaultState: IHistoricalStats[] = [];

export const historical_stats = createReducer<typeof defaultState>({}, defaultState).on(
	fetchHistoricalSuccess,
	(state, payload) => payload
);
