import { applyMiddleware, combineReducers, createStore } from 'redux';
import { default as createSagaMiddleware } from 'redux-saga';
import * as reducers from './reducers';
import { rootSaga } from './sagas';

const sagaMiddleware = createSagaMiddleware();

export default createStore(
	combineReducers(reducers),
	applyMiddleware(sagaMiddleware),
);

sagaMiddleware.run(rootSaga);