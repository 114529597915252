import React, {useCallback, useState} from "react";
import styled from "styled-components";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput} from "@mui/material";
import {PASSWORD_REGEXP_STR} from "modules/utils";

const FormControlStyled = styled(FormControl)``;

const Wrapper = styled.div`
	width: 100%;
	position: relative;
	${FormControlStyled} {
		margin-bottom: 20px;
		width: 100%;
	}
`;

interface IProps {
	name: string;
	id: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
	label?: string;
	noPattern?: boolean;
}

export const PasswordField: React.FC<IProps> = (props) => {
	const [showPassword, changeType] = useState(false);

	const handleType = useCallback(() => {
		changeType(!showPassword);
	}, [showPassword]);

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const label = props.label || "Password";
	const inputProps = props.noPattern
		? {}
		: {
				pattern: PASSWORD_REGEXP_STR,
		  };

	return (
		<Wrapper>
			<FormControlStyled variant="outlined">
				<InputLabel htmlFor={props.id}>{label}</InputLabel>
				<OutlinedInput
					id={props.id}
					type={showPassword ? "text" : "password"}
					name={props.name}
					onChange={props.onChange}
					inputProps={{...inputProps, onBlur: props.onBlur || undefined}}
					required={true}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={handleType}
								onMouseDown={handleMouseDownPassword}
								type="button"
								edge="end">
								{showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					}
					label={label}
				/>
			</FormControlStyled>
		</Wrapper>
	);
};
