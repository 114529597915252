import styled from "styled-components";
import {PureButton} from "components/FormElements/PureButton";

export const ButtonPrimary = styled(PureButton)`
	font-family: var(--fontFamilyBase);
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 118%;
	background-color: var(--primaryColor);
	border: none;
	border-radius: 4px;
	max-width: 242px;
	height: 48px;
	color: #000;
	width: 100%;
	margin: 0 auto;
	cursor: pointer;
	outline: none;
	display: block;
	transition: all 250ms ease-in-out;

	&:disabled {
		background: rgb(188, 188, 188);
		cursor: default;
		color: #6a6a6a;
	}

	&:hover:not(:disabled) {
		background: #aa0000;
		color: #fff;
	}
	&.link {
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;
