import {createReducer} from "redux-act";
import {fetchRidersJSONSuccess} from "modules/actions";
import {IRider} from "modules/types";

const defaultState: IRider[] = [];

export const riders = createReducer<typeof defaultState>({}, defaultState).on(
	fetchRidersJSONSuccess,
	(state, payload) => payload
);
