import {IStore} from "modules/types";
import {getUserId} from "modules/selectors";
import {createSelector} from "reselect";
import {isEmpty} from "lodash";
import {RequestStateType} from "modules/utils";

export const getPredictorState = (store: IStore) => store.predictor;

export const getUserPrediction = createSelector(
	getPredictorState,
	(predictor) => predictor.user_prediction
);

export const getPrediction = createSelector(
	getUserPrediction,
	(user_prediction) => user_prediction.prediction
);
export const getQuestion = createSelector(
	getUserPrediction,
	(user_prediction) => user_prediction.question
);

export const getPredictorTimeUserResult = createSelector(
	getPredictorState,
	(predictor) => predictor.user_prediction.prediction?.timeResult
);

export const isPredictionLocked = createSelector(
	getUserPrediction,
	(user_prediction) => user_prediction.question.locked
);

export const isPredictionSaving = createSelector(
	getPredictorState,
	(predictor) => predictor.is_result_saving
);

export const isPredictionSaved = createSelector(
	getPredictorState,
	(predictor) => predictor.is_result_saved
);

export const getTimerState = createSelector(
	getPredictorState,
	(predictor) => predictor.timer_ended
);

export const getTimeInputLengths = createSelector(
	getPredictorState,
	(predictor) => predictor.time_input_length
);

export const isShowResults = createSelector(getQuestion, (question) => {
	return question.locked && question.timeResult !== null;
});

export const getGameBar = createSelector(getPredictorState, (predictor) => predictor.game_bar);
export const getLeaderBoard = createSelector(
	getPredictorState,
	(predictor) => predictor.leaderboard.rankings
);

export const isMoreLeaderboard = createSelector(
	getPredictorState,
	(predictor) => predictor.leaderboard.nextPage
);

export const getLeaderboardIndex = createSelector(
	getLeaderBoard,
	getUserId,
	(leaderboard, user_id) => {
		return leaderboard.findIndex((member) => {
			return member.userId !== user_id && !member.isFriend;
		});
	}
);
export const isLeaderboardRequested = createSelector(
	getPredictorState,
	(predictor) =>
		isEmpty(predictor.leaderboard.rankings) &&
		predictor.leaderboardRequest === RequestStateType.Requested
);
export const isLeaderboardPreseason = createSelector(
	getPredictorState,
	(predictor) =>
		isEmpty(predictor.leaderboard.rankings) &&
		predictor.leaderboardRequest === RequestStateType.Received
);
