import {IStore} from "modules/types";
import {createSelector} from "reselect";
import {chain, find} from "lodash";

export const getFaq = ({help}: IStore) => help.faq;
export const getHelpPages = ({help}: IStore) => help.help_pages;

export const getHelpContent = ({help}: IStore) => help.help_content;

export const getTabs = createSelector(getHelpContent, (help) => {
	if (!help) {
		return [];
	}
	console.log(help);
	// Find category id for help pages
	const category = help.categories.categories.find((category) => category.name === "Help");

	return chain(help.sections.sections)
		.filter((section) => section.category_id === category?.id)
		.sortBy("position")
		.value();
});

export const getSectionIdByDescription = createSelector(
	getHelpContent,
	(help) => (section_description?: string) => {
		const section = find(
			help?.sections.sections,
			(section) => section.description === section_description
		);
		return section?.id || 0;
	}
);

export const getHelpPageContentById = createSelector(
	getHelpContent,
	(help) => (section_id: number) => {
		if (!help) {
			return [];
		}
		return chain(help.articles.articles)
			.filter((article) => article?.section_id === section_id)
			.orderBy(["position"], ["asc"])
			.value();
	}
);
