import {IStore} from "modules/types";
import {createSelector} from "reselect";
import {CrossPromotionModalType} from "modules/utils";

export const getCrossPromotionState = (state: IStore) => state.modals.crossPromotion;
export const isNeedToShowCrossPromotion = createSelector(
	getCrossPromotionState,
	(state) => state === CrossPromotionModalType.Show
);
export const isCrossPromotionShown = createSelector(
	getCrossPromotionState,
	(state) => state === CrossPromotionModalType.Hidden
);

export const isLoginFormVisible = (state: IStore) => state.modals.login_modal_visible;
export const isRegistrationFormVisible = (state: IStore) => state.modals.registration_modal_visible;
export const isForgottenPasswordFormVisible = (state: IStore) =>
	state.modals.forgot_pass_modal_visible;
export const isChangePasswordFormVisible = (state: IStore) =>
	state.modals.change_pass_modal_visible;
