import React, {useCallback, useEffect, useState} from "react";
import {CloseModalButton, ModalInner, ModalOverlay, ModalTitle} from "components/Modal";
import {ReactComponent as Logo} from "assets/img/logo-dark.svg";
import {PasswordField, FieldText, ButtonPrimary, PureButton} from "components/FormElements";
import {useDispatch, useSelector} from "react-redux";
import {isLoginFormVisible, isLogInSuccess} from "modules/selectors";
import styled from "styled-components";
import {FormControl, InputLabel} from "@mui/material";
import {IconClose} from "components/Icons";
import {
	hideModalLogin,
	postLogin,
	showModalForgottenPassword,
	showModalRegistration,
	resetUserStore,
} from "modules/actions";

const FormControlStyled = styled(FormControl)``;

const Details = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	${ModalTitle} {
		margin: 20px auto;
	}

	form {
		width: 440px;
		max-width: 100%;
		display: flex;
		flex-direction: column;
		* > {
			box-sizing: border-box;
		}
	}

	${FormControlStyled} {
		width: 100%;
		margin-bottom: 20px;
	}

	${ButtonPrimary} {
		width: 100%;
		max-width: 100%;
	}
`;

const ForgotPasswordButton = styled(PureButton)`
	color: var(--primaryColor);
	text-align: right;
	font-family: var(--fontFamilySecondary);
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 140%;
	margin-bottom: 20px;
	justify-self: flex-end;
	transition: all 250ms ease-in-out;
	&:hover {
		color: #aa0000;
	}
`;

const FormFooter = styled.p`
	text-align: center;
	font-family: var(--fontFamilySecondary);
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 140%;
	margin: 20px auto;

	${PureButton} {
		color: var(--primaryColor);
		transition: all 250ms ease-in-out;
		font-family: var(--fontFamilySecondary);
		font-weight: 500;
		font-size: 14px;
		&:hover {
			color: #aa0000;
		}
	}
`;

export const ModalLogin: React.FC = () => {
	const isVisible = useSelector(isLoginFormVisible);
	const [form, changeForm] = useState({email: "", password: ""});
	const [formValidity, setIsFormValid] = useState({email: false, password: false});
	const dispatch = useDispatch();
	const isRequestComplete = useSelector(isLogInSuccess);

	useEffect(() => {
		dispatch(resetUserStore());
	}, [dispatch]);

	const handleForm = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const {value, name, validity} = e.currentTarget;

			changeForm({
				...form,
				[name]: value,
			});
			setIsFormValid({
				...formValidity,
				[name]: validity.valid,
			});
		},
		[form, formValidity]
	);
	const closeModal = useCallback(() => {
		dispatch(hideModalLogin());
	}, [dispatch]);

	const submitForm = useCallback(
		(e: React.SyntheticEvent<HTMLFormElement>) => {
			e.preventDefault();
			e.stopPropagation();

			dispatch(postLogin(form));
			closeModal();
		},
		[dispatch, form, closeModal]
	);

	const handleForgotPasswordModal = () => {
		closeModal();
		dispatch(showModalForgottenPassword());
	};

	const handleRegistrationModal = () => {
		closeModal();
		dispatch(showModalRegistration());
	};

	useEffect(() => {
		if (isRequestComplete && isVisible) {
			closeModal();
		}
	}, [isRequestComplete, isVisible, closeModal]);

	if (!isVisible) {
		return null;
	}
	const isDisabled = !formValidity.email || !formValidity.password;

	return (
		<ModalOverlay>
			<ModalInner>
				<CloseModalButton onClick={closeModal}>
					<IconClose />
				</CloseModalButton>
				<Details>
					<Logo />

					<ModalTitle>Log in to your account</ModalTitle>

					<form onSubmit={submitForm}>
						<FormControlStyled>
							<InputLabel htmlFor="email">Email Address</InputLabel>
							<FieldText
								onChange={handleForm}
								label="Email Address"
								name="email"
								type="email"
								id="email"
							/>
						</FormControlStyled>
						<PasswordField
							noPattern={true}
							onChange={handleForm}
							name="password"
							id="password"
						/>

						<ForgotPasswordButton onClick={handleForgotPasswordModal} type="button">
							Forgot Password?
						</ForgotPasswordButton>

						<ButtonPrimary disabled={isDisabled}>Log in</ButtonPrimary>

						<FormFooter>
							New?{" "}
							<PureButton onClick={handleRegistrationModal}>Register Now</PureButton>
						</FormFooter>
					</form>
				</Details>
			</ModalInner>
		</ModalOverlay>
	);
};
