import Input from "./Input";
import styled from "styled-components";
import zoom from "assets/img/zoom.svg";

export const SearchInput = styled(Input)`
	background-image: url("${zoom}");
	background-repeat: no-repeat;
	background-position: calc(100% - 20px) center;
	background-size: 18px;
	padding: 0 20px 0 12px;
	border: 1px solid #bcbcbc;
	border-radius: 4px;
	height: 54px;
	font-family: var(--fontFamilySecondary);
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 118%;
	color: #bcbcbc;
`;
