import styled from 'styled-components';
import { IInput } from 'components';
import arrow from 'assets/img/accordion-arrow.svg'

export const Select = styled.select<IInput>`
	border: 1px solid ${({has_error}) => has_error ? '#C80502' : '#D2D2D2'};
	height: 40px;
	width: 100%;
	padding-left: 10px;
	background: #FFFFFF url("${arrow}") right 20px center no-repeat;
	background-size: 12px;
	font-size: 12px;

	> option {
		padding-left: 20px;
	}

	appearance: none;
`;

export default Select;
