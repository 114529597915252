import React from 'react';
import styled, { css } from 'styled-components';
import sub_header_bg from 'assets/img/sub_header_bg.png';
import sub_header_bg_mobile from 'assets/img/sub_header_bg_mobile.png';

const getBG = () => {
	return css`
		background-image: url(${sub_header_bg});
		background-position: center;
		@media screen and (max-width: 992px ) {
			background-image: url(${sub_header_bg_mobile});
		}`;
}

const SubHeaderWrapper = styled.div`
	width: 100%;
	height: 170px;

	background-size: cover;

	background-repeat: no-repeat;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 992px ) {
		height: 120px;
	}

	h1 {
		font-size: 40px;
		line-height: 30px;
		color: #FFFFFF;
		font-weight: 700;
		text-transform: uppercase;
		margin-bottom: 20px;
		text-align: center;

		&.text-normal {
			text-transform: none;
		}

		@media screen and (max-width: 992px ) {
			font-size: 30px;
			margin-bottom: 5px;
		}
	}

	p {
		text-align: center;
		font-size: 16px;
		line-height: 19px;
		color: #EAEAEA;
		max-width: 425px;
	}

	${getBG}
`;

interface ISubHeader {
	title?: string;
	description?: string;
	titleClass?: string;
}

export const SubHeader: React.FC<ISubHeader> = ({title, description, titleClass}) => (
	<SubHeaderWrapper>
		{title && <h1 className={titleClass ? titleClass : ''}>{title}</h1>}
		{description && <p>{description}</p>}
	</SubHeaderWrapper>
);