import styled from "styled-components";

export const PageHeader = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 20px auto;
	h2 {
		color: #000000;
		font-family: var(--fontFamilyBase);
		font-style: normal;
		font-weight: 700;
		font-size: 26px;
		line-height: 120%;
	}
	p,
	li {
		color: #000000;
		var(--fontFamilySecondary);
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 140%;
	}

	@media screen and (max-width: 960px) {
		flex-direction: column;
		> div {
			width: 100%;
		}
	}
`;
