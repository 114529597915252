import styled from "styled-components";

export const PureButton = styled.button`
	border: 0;
	padding: 0;
	margin: 0;
	background: none;
	cursor: pointer;
	appearance: none;
`;
