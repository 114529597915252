import {OutlinedInput} from "@mui/material";
import styled from "styled-components";

export const FieldText = styled(OutlinedInput)`
	font-family: var(--fontFamilySecondary);
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	color: #000;
	width: 100%;
`;
