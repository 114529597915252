import React, {useCallback, useEffect, useState} from "react";
import {ModalOverlay} from "components/Modal";
import styled from "styled-components";
import {ButtonPrimary, CheckBox, FormGroup} from "components/FormElements";
import {useDispatch, useSelector} from "react-redux";
import {userReturn} from "modules/actions";
import {ReturnFormNames} from "modules/utils";
import {isReturningUser} from "modules/selectors";
import logo from "assets/img/logo-dark.svg";

const Modal = styled.div`
	background: #fff;
	width: 100%;
	max-width: 560px;
	box-sizing: border-box;
	max-height: 100%;
	max-height: 100vh;
	position: relative;
	overflow: auto;
	
	form {
		border-top: 1px solid var(--Greys-Mid-dark-grey, #999898);
		padding-top: 20px;
	}

	form > div {
		margin: 0 auto;
		width: 100%;
		max-width: 440px;
	}
	button {
		margin-top: 10px;
	}
`;
const ModalHeader = styled.header`
	height: 62px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const ModalInner = styled.div`
	padding: 0 20px 30px;
`;

// const Text = styled.p`
// 	color: #232323;
// 	font-size: 14px;
// 	letter-spacing: 0;
// 	line-height: 19px;
// 	text-align: center;
// 	margin-bottom: 10px;
// `;

export const ModalReturningUser: React.FC = () => {
	const dispatch = useDispatch();
	const is_returning_user = useSelector(isReturningUser);
	const [values, updateValue] = useState({
		[ReturnFormNames.Sponsor]: false,
		[ReturnFormNames.Terms]: false,
	});
	const [isDisabled, handleSubmitButton] = useState(true);

	const handleChange = useCallback(
		(event: React.SyntheticEvent<HTMLInputElement>) => {
			const {name, checked} = event.currentTarget;
			updateValue({
				...values,
				[name]: checked,
			});
		},
		[values]
	);

	const onSubmit = useCallback(
		(event: React.SyntheticEvent<HTMLFormElement>) => {
			event.preventDefault();
			event.stopPropagation();

			if (!event.currentTarget.checkValidity()) {
				return;
			}
			dispatch(
				userReturn()
			);
		},
		[dispatch]
	);

	useEffect(() => {
		handleSubmitButton(!values[ReturnFormNames.Terms] ?? !values[ReturnFormNames.Sponsor]);
	}, [values]);

	if (!is_returning_user) {
		return null;
	}
	return (
		<ModalOverlay>
			<Modal>
				<ModalHeader>
					<img src={logo} alt="" />
				</ModalHeader>
				<ModalInner>
					<form onSubmit={onSubmit}>
						<FormGroup>
							<CheckBox
								name={ReturnFormNames.Sponsor}
								tick_position="left"
								required={true}
								onChange={handleChange}>
								I have read and accept the{" "}
								<a
									href="https://www.predictor.worldsbk.com/help/terms-and-conditions"
									rel="noopener noreferrer"
									target="_blank">
									Terms & Conditions
								</a>{" "}
								of the Predictor WorldSBK Game, confirming that I am over 16 years old. DORNA SPORTS, S.L. will process your personal data in accordance with this{" "}
								<a
									href="https://www.predictor.worldsbk.com/help/privacy-policy"
									target="_blank"
									rel="noopener noreferrer">
									Privacy Policy
								</a>. You are able to exercise your rights at all times contacting{" "}
								<a
									href="mailto:dpo@dorna.com"
									target="_blank"
									rel="noopener noreferrer">
									dpo@dorna.com
								</a>
							</CheckBox>
						</FormGroup>
						<FormGroup>
							<CheckBox
								name={ReturnFormNames.Terms}
								tick_position="left"
								required={true}
								onChange={handleChange}>
								I agree to participate at the Predictor GP Reward Contest (organized by Tissot SA) by accepting these <a
								href="https://www.tissotwatches.com/media/downloadable/T_C_tissot_contest_sbk_predictor_2024.pdf" target="_blank"
								rel="noopener noreferrer">T&Cs</a>. I accept, as well, that DORNA communicates my personal data to Tissot SA for processing my participation in the contest and for sending me the TISSOT newsletter. I acknowledge and accept that Tissot SA and its affiliate in my country will process my data for this purpose. I confirm to have read and understood the <a
								href="https://www.tissotwatches.com/en-gb/privacy-notice.html" target="_blank"
								rel="noopener noreferrer">Privacy Notice</a>
							</CheckBox>
						</FormGroup>
						<ButtonPrimary disabled={isDisabled} id="register-button" type="submit">
							Start Predicting
						</ButtonPrimary>
					</form>
				</ModalInner>
			</Modal>
		</ModalOverlay>
	);
};
