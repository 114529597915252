import {IStore} from "modules/types";
import {createSelector} from "reselect";
import {EventStatusType} from "modules/utils";
import {find, last, memoize, matches, first, findLast, keyBy} from "lodash";

export const getEvents = (state: IStore) => state.events;
export const getEventsByPosition = createSelector(getEvents, (events) => keyBy(events, "position"));

// OFF Season hack
const SHOW_LAST_COMPLETE_EVENT = false;
export const getActualEvent = createSelector(getEvents, (events) => {
	if (SHOW_LAST_COMPLETE_EVENT) {
		return findLast(events, ({status}) => EventStatusType.Complete === status);
	}
	return (
		find(events, ({status}) =>
			[EventStatusType.Scheduled, EventStatusType.Active].includes(status)
		) || last(events)
	);
});

export const getFirstScheduledEvent = createSelector(getEvents, (events) => {
	if (SHOW_LAST_COMPLETE_EVENT) {
		return findLast(events, ({status}) => EventStatusType.Complete === status);
	}
	return find(events, ({status}) => EventStatusType.Scheduled === status) || last(events);
});

export const getEventsFromFirstToActual = createSelector(
	getEvents,
	getActualEvent,
	(events, actual_event) => {
		if (!events || !actual_event) {
			return [];
		}
		return events.filter(({position}) => actual_event.position >= position);
	}
);

export const getSelectedEventSelector = createSelector(
	getEvents,
	getActualEvent,
	(events, actualEvent) =>
		memoize((position: number = 0) => {
			return position ? find(events, matches({position})) : actualEvent;
		})
);

export const getLastCompleteEvent = createSelector(
	getEvents,
	(events) => findLast(events, ({status}) => EventStatusType.Complete === status) || first(events)
);
