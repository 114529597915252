import * as React from 'react';
import styled from 'styled-components';

const PreloaderWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const PreloaderContent = styled.div`
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
	
	@keyframes lds-ellipsis1 {
	  0% {
	    transform: scale(0);
	  }
	  100% {
	    transform: scale(1);
	  }
	}
	
	@keyframes lds-ellipsis3 {
	  0% {
	    transform: scale(1);
	  }
	  100% {
	    transform: scale(0);
	  }
	}
	
	@keyframes lds-ellipsis2 {
	  0% {
	    transform: translate(0, 0);
	  }
	  100% {
	    transform: translate(24px, 0);
	  }
	}
	
	div {
		position: absolute;
		top: 33px;
		width: 13px;
		height: 13px;
		border-radius: 50%;
		background: #000;
		animation-timing-function: cubic-bezier(0, 1, 1, 0);
		
		&:nth-child(1) {
			left: 8px;
			animation: lds-ellipsis1 0.6s infinite;
		}
		
		&:nth-child(2) {
		  left: 8px;
		  animation: lds-ellipsis2 0.6s infinite;
		}
		
		&:nth-child(3) {
			left: 32px;
			animation: lds-ellipsis2 0.6s infinite;
		}
		
		&:nth-child(4) {
			left: 56px;
			animation: lds-ellipsis3 0.6s infinite;
		}
	}
`;

export const Preloader: React.FC = () => (
	<PreloaderWrapper>
		<PreloaderContent>
			<div/><div/><div/><div/>
		</PreloaderContent>
	</PreloaderWrapper>
);

export default Preloader;