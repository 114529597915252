import { createReducer } from 'redux-act';
import * as actions from 'modules/actions';
import { IError } from "modules/types";

interface IErrorReducer {
	text?: string;
}

export const errors = createReducer<IErrorReducer>({}, {})
	.on(actions.showGlobalError, (state: IErrorReducer, { text }: IError) => ({ text }))
	.on(actions.clearGlobalError, () => ({}));
