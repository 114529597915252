import {getTimerState} from "modules/selectors";
import {useDispatch, useSelector} from "react-redux";
import React, {PropsWithChildren, useEffect} from "react";
import {subscribeToLiveScores, unsubscribeFromLiveScores} from "modules/actions";

export const HOCLiveScores: React.FC<PropsWithChildren> = ({children}) => {
	const timer_state = useSelector(getTimerState);

	const dispatch = useDispatch();
	useEffect(() => {
		if (timer_state) {
			dispatch(subscribeToLiveScores());
		} else {
			dispatch(unsubscribeFromLiveScores());
		}

		return () => {
			dispatch(unsubscribeFromLiveScores());
		};
	}, [dispatch, timer_state]);

	return <React.Fragment>{children}</React.Fragment>;
};
