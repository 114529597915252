import React, {PropsWithChildren, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
	getUserObject,
	isAdditionalDataRequired,
	isLoggedIn,
	isSessionChecked,
} from "modules/selectors";
import {userFetch} from "modules/actions";
import Preloader from "components/Preloader";

export const HOCSession: React.FC<PropsWithChildren> = ({children}) => {
	const dispatch = useDispatch();
	const is_logged_in = useSelector(isLoggedIn);
	const is_session_checked = useSelector(isSessionChecked);
	const is_additional_data_required = useSelector(isAdditionalDataRequired);
	const user = useSelector(getUserObject);

	useEffect(() => {
		if (!is_logged_in && !is_additional_data_required) {
			dispatch(userFetch());
		}
	}, [dispatch, is_logged_in, is_additional_data_required]);

	useEffect(() => {
		window.dataLayer.push({
			user_id: user.dornaId,
		});
	}, [user.dornaId]);

	return is_session_checked ? <React.Fragment>{children}</React.Fragment> : <Preloader />;
};
