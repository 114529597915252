import {Constant, HELP_JSON_URL} from "modules/utils/Constant";
import {
	IApiResponse,
	IChecksum,
	ICountry,
	ICreateUserPayload,
	IEvent,
	IFaqItem,
	IForgotPasswordPayload,
	IGameBar,
	IHelpArticle,
	IHelpCategory,
	IHelpPages,
	IHelpSection,
	IHistoricalStats,
	ILeaderboard,
	ILeaderboardRequest,
	IPostContactForm,
	IPostLoginPayload,
	IPredictionSavePayload,
	IQuestionPredictionPayload,
	IQuestionPredictionResponse,
	IRestorePasswordPayload,
	IRider,
	IUser,
	IUserBackdoorLoginPayload,
	IUserRegisterForGamePayload,
	IUserUpdate,
} from "modules/types";
import {HttpClientService} from "./HTTPClient";

export const IS_API_ON_THE_SAME_HOST = Constant.API_URL.includes(window.location.host);

const APIClient = new HttpClientService({
	baseURL: Constant.API_URL,
	withCredentials: true,
});

const JSONClient = new HttpClientService({
	baseURL: Constant.JSON_URL,
});

const HelpJSONClient = new HttpClientService({
	baseURL: HELP_JSON_URL,
});

type TUserResponse = IApiResponse<{user: IUser; session_id: string; isRegistered: boolean}>;

type TPredictionResponse = IApiResponse<IQuestionPredictionResponse>;

type TGameBarResponse = IApiResponse<IGameBar>;
type TLeaderboardResponse = IApiResponse<ILeaderboard>;

export const Api = {
	JSON: {
		checksums: () => JSONClient.get<IChecksum>("checksums.json"),
		events: () => JSONClient.get<IEvent[]>("events.json"),
		riders: () => JSONClient.get<IRider[]>("riders.json"),
		historical_stats: () => JSONClient.get<IHistoricalStats[]>("historical_stats.json"),
		// constructors: () => JSONClient.get<Record<string, unknown>>('constructors.json'),
		// squads: () => JSONClient.get<Record<string, unknown>>('squads.json'),
		faq: () => JSONClient.get<IFaqItem[]>("faq.json"),
		help_pages: () => JSONClient.get<IHelpPages>("help_pages.json"),
		countries: () => JSONClient.get<ICountry[]>("countries.json"),
		widget: () => JSONClient.get<Record<string, unknown>>("widget.json"),

		categories: () => HelpJSONClient.get<IHelpCategory>("categories.json"),
		sections: () => HelpJSONClient.get<IHelpSection>("sections.json"),
		articles: () => HelpJSONClient.get<IHelpArticle>("articles.json"),
	},
	Auth: {
		// SSO
		// login: (params: IUserLoginPayload) => APIClient.post<TUserResponse>("auth/login", params),
		backdoor: (params: IUserBackdoorLoginPayload) =>
			APIClient.post<TUserResponse>("auth/backdoor", params),
		logout: () => APIClient.post("auth/logout"),

		// No SSO
		login: (params: IPostLoginPayload) => APIClient.post<TUserResponse>("auth/login", params),
		register: (params: ICreateUserPayload) =>
			APIClient.post<TUserResponse>("auth/register", params),
		password_reset_request: (params: IForgotPasswordPayload) =>
			APIClient.post<IApiResponse>("auth/password_reset/request", params),
		password_reset: (params: IRestorePasswordPayload) =>
			APIClient.post<IApiResponse>("auth/password_reset", params),
	},
	User: {
		recover: () => APIClient.post<TUserResponse>("user/recover"),
		register_for_game: (params: IUserRegisterForGamePayload) =>
			APIClient.post<TUserResponse>("user/register_for_game", params),
		update: (params: IUserUpdate) => APIClient.post<TUserResponse>("user/update", params),
		show_my: () => APIClient.get<TUserResponse>("user"),
		delete_friend: (params: {friendId: number}) => APIClient.post("friend/delete", params),
		add_friend: (params: {friendId: number}) => APIClient.post("friend/add", params),
	},
	Contact: {
		save: (params: IPostContactForm) => APIClient.post("contact", params),
	},
	Predictor: {
		show_my: (params: IQuestionPredictionPayload) =>
			APIClient.get<TPredictionResponse>("prediction/show", params),
		save: (params: IPredictionSavePayload) =>
			APIClient.post<TPredictionResponse>("prediction/save", params),
		game_bar: (params: IQuestionPredictionPayload) =>
			APIClient.get<TGameBarResponse>("gamebar", params),
		leaderboard: (params: ILeaderboardRequest) =>
			APIClient.get<TLeaderboardResponse>("leaderboard", params),
	},
};

export * from "./ApiError";

export default Api;
