import styled from "styled-components";

export const Content = styled.main`
	//flex: 1;
`;

export const Container = styled.div`
	width: 100%;
	max-width: 1240px;
	padding: 0 20px;
	box-sizing: border-box;
	margin: 0 auto;
	position: relative;
`;

export const InnerContainer = styled(Container)``;

export const InnerContainerSM = styled(Container)`
	max-width: 920px;
`;

export * from "./SubHeader";
