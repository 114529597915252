import styled from "styled-components";

export const ModalInner = styled.div`
	background: #fff;
	width: 100%;
	max-width: 500px;
	padding: 20px 30px;
	box-sizing: border-box;
	max-height: 90vh;
	position: relative;
	overflow: auto;
	border-radius: 5px;
`;

export const CloseModalButton = styled.button`
	background: none;
	padding: 0;
	border: 0;
	position: absolute;
	right: 10px;
	top: 10px;
	cursor: pointer;
`;

export const ModalTitle = styled.h2`
	font-family: var(--fontFamilyBase);
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 104%;
	text-align: center;
	color: #000000;
`;

export const ModalDescription = styled.p`
	font-family: var(--fontFamilySecondary);
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 140%;
	text-align: center;
	margin-bottom: 20px;
`;

export * from "./ModalError";
export * from "./ModalOverlay";
