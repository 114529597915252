import {all, takeLatest} from "typed-redux-saga";
import * as actions from "modules/actions";
import * as sagas from "modules/sagas";

/**
 * Root saga that connect sagas with actions.
 */
export const rootSaga = function* () {
	yield all([
		/**
		 * Auth
		 */
		takeLatest(actions.backdoorLogin, sagas.userBackdoorLoginSaga),
		takeLatest(actions.userLogout, sagas.userLogoutSaga),
		// takeLatest(actions.userLogin, sagas.userLoginSaga),
		takeLatest(actions.postLogin, sagas.postLoginSaga),
		takeLatest(actions.postUserCreate, sagas.userRegistrationSaga),
		takeLatest(actions.postForgotPassword, sagas.postForgotPasswordSaga),
		takeLatest(actions.postRestorePassword, sagas.postRestorePasswordSaga),

		/**
		 * User
		 */
		// takeLatest(actions.userCreate, sagas.userCreateSaga),
		takeLatest(actions.userUpdate, sagas.userUpdateSaga),
		takeLatest(actions.userReturn, sagas.userReturnSaga),
		// takeLatest(actions.userRegisterForGame, sagas.userRegisterForGameSaga),
		takeLatest(actions.userFetch, sagas.userFetchSaga),
		takeLatest(actions.postUserLogout, sagas.postUserLogoutSaga),
		takeLatest(actions.postHandleUserFriend, sagas.postHandleUserFriendSaga),

		/**
		 * Global
		 */
		takeLatest(actions.globalError, sagas.errorsManagerSaga),

		/**
		 * JSONs
		 */
		takeLatest(actions.fetchChecksumsJSON, sagas.fetchChecksumsJSONSaga),
		// takeLatest(actions.fetchConstructorsJSON, sagas.fetchConstructorsJSONSaga),
		takeLatest(actions.fetchEventsJSON, sagas.fetchEventsJSONSaga),
		takeLatest(actions.fetchRidersJSON, sagas.fetchRidersJSONSaga),
		takeLatest(actions.fetchHistoricalStats, sagas.fetchHistoricalStatsSaga),
		takeLatest(actions.fetchHelpPages, sagas.fetchHelpPagesSaga),
		takeLatest(actions.fetchFaq, sagas.fetchFaqSaga),
		takeLatest(actions.fetchCountries, sagas.fetchCountriesSaga),
		takeLatest(actions.fetchHelpJSONs, sagas.fetchHelpJSONs),

		/**
		 * Contact
		 */
		takeLatest(actions.postContactForm, sagas.postContactFormSaga),

		/**
		 * Predictor
		 */
		takeLatest(actions.fetchUserPrediction, sagas.fetchUserPredictionSaga),
		takeLatest(actions.postPredictorPrediction, sagas.postPredictorPredictionSaga),
		takeLatest(actions.fetchGameBar, sagas.fetchGameBarSaga),
		takeLatest(actions.fetchLeaderBoard, sagas.fetchLeaderBoardSaga),

		/**
		 * Live Score
		 */
		takeLatest(actions.subscribeToLiveScores, sagas.ChecksumsToSaga),
	]);
};

export * from "./errors";
export * from "./user";
export * from "./jsons";
export * from "./contact";
export * from "./predictor";
export * from "./live_score";
