import {
	IApiResponse,
	ICreateUserPayload,
	IError,
	IForgotPasswordPayload,
	IPostLoginPayload,
	IRestorePasswordPayload,
	ISagaAction,
	IUserBackdoorLoginPayload,
	IUserFriendPayload,
	IUserUpdate,
} from "modules/types";
import {call, delay, put} from "typed-redux-saga";
import {Api, ApiError, User} from "modules/utils";
import * as actions from "modules/actions";
import {get} from "lodash";

export const userBackdoorLoginSaga = function* ({payload}: ISagaAction<IUserBackdoorLoginPayload>) {
	try {
		const {data: response} = yield* call(Api.Auth.backdoor, payload);
		ApiError.CHECK(response);

		if (response.success) {
			const {user, session_id} = response.success;
			User.SAVE(user, session_id);

			yield* put(actions.backdoorLoginSuccess(user));
		} else {
			yield* put(actions.backdoorLoginFailed({text: "Login Failed"}));
		}
	} catch (e) {
		yield* put(actions.globalError(e as IError));
		yield* put(actions.backdoorLoginFailed(e as IError));
	}
};

export const userLogoutSaga = function* () {
	try {
		User.CLEAR();

		yield* put(actions.userLogoutSuccess());
	} catch (e) {
		yield* put(actions.globalError(e as IError));
		yield* put(actions.userLogoutFailed(e as IError));
	}
};

// SSO Login
// export const userLoginSaga = function* ({payload}: ISagaAction<IUserLoginPayload>) {
// 	try {
// 		const {data: response} = yield* call(Api.Auth.login, payload);
// 		ApiError.CHECK(response);
// 		if (response.success) {
// 			const {user, session_id, isRegistered} = response.success;
//
// 			if (isRegistered) {
// 				User.SAVE(user, session_id);
//
// 				yield* put(actions.userLoginSuccess(user));
// 			} else {
// 				yield* put(actions.userAskForRegistration(user));
// 			}
// 		} else {
// 			yield* put(actions.userLoginFailed({text: "Login Failed"}));
// 		}
// 	} catch (e) {
// 		const code = get(e, "code");
// 		if (code === 515) {
// 			yield* put(actions.userAskForReturn());
// 		} else {
// 			yield* put(actions.userLoginFailed(e as IError));
// 			yield* put(actions.globalError(e as IError));
// 		}
// 	}
// };

export const userUpdateSaga = function* (action: ISagaAction<IUserUpdate>) {
	try {
		const {data: response} = yield* call(Api.User.update, action.payload);
		ApiError.CHECK(response);

		if (response.success) {
			yield* put(actions.userUpdateSuccess(response.success.user));

			yield* delay(2000);

			yield* put(actions.resetUserUpdateSuccess());
		}
	} catch (e) {
		yield* put(actions.userUpdateFailed(e as IError));
	}
};

export const userReturnSaga = function* () {
	try {
		const {data: response} = yield* call(Api.User.recover);

		ApiError.CHECK(response);
		if (response.success) {
			const {user, session_id} = response.success;
			User.SAVE(user, session_id);

			yield* put(actions.userReturnSuccess(user));
		}
	} catch (e) {
		yield* put(actions.userCreateFailed(e as IError));
		yield* put(actions.globalError(e as IError));
	}
};

export const userFetchSaga = function* () {
	try {
		const {data: response} = yield* call(Api.User.show_my);
		ApiError.CHECK(response);

		if (response.success && response.success.user !== null) {
			const {user, session_id} = response.success;
			User.SAVE(user, session_id);

			yield* put(actions.userFetchSuccess(response.success.user));
		} else {
			yield* put(actions.userFetchFailed({text: "User is null"}));
		}
	} catch (e) {
		const code = get(e, "code");
		if (code === 515) {
			yield* put(actions.userAskForReturn());
		} else {
			yield* put(actions.userFetchFailed(e as IError));
		}
	}
};

export const postUserLogoutSaga = function* () {
	try {
		const {data: response} = yield* call(Api.Auth.logout);
		ApiError.CHECK(response as IApiResponse);
		// const script = document.createElement("script");
		// const callbackName = `jQuery${Date.now()}`;

		// set(window, callbackName, () => {
		// 	User.CLEAR();
		// 	window.location.href = `${SSO_URL}en/logout?redirect_uri=${window.location.origin}`;
		// });
		// script.src = `${SSO_URL_SCRIPT}en/user/logout/?callback=${callbackName}`;
		// document.body.appendChild(script);
		yield* put(actions.clearPredictionState());
		yield* put(actions.postUserLogoutSuccess());
		User.CLEAR();
		// window.location.href = `${SSO_URL}en/logout?redirect_uri=${window.location.origin}`;
	} catch (e) {
		yield* put(actions.globalError(e as IError));
	}
};

export const postHandleUserFriendSaga = function* ({payload}: ISagaAction<IUserFriendPayload>) {
	try {
		const apiName = payload.isFriend ? "delete_friend" : "add_friend";

		const {data: response} = yield* call(Api.User[apiName], {
			friendId: payload.friendId,
		});

		ApiError.CHECK(response as IApiResponse);
	} catch (e) {
		yield* put(actions.userCreateFailed(e as IError));
		yield* put(actions.globalError(e as IError));
	}
};

// No SSO
export const userRegistrationSaga = function* ({payload}: ISagaAction<ICreateUserPayload>) {
	try {
		const {data: response} = yield* call(Api.Auth.register, payload);
		ApiError.CHECK(response);

		if (response.success) {
			const {user} = response.success;
			// User.SAVE(user, session_id);

			yield* put(actions.userCreateSuccess(user));
		}
	} catch (e) {
		yield* put(actions.postUserCreateFailed(e as IError));
		// yield* put(actions.globalError(e as IError));
	}
};

export const postLoginSaga = function* ({payload}: ISagaAction<IPostLoginPayload>) {
	try {
		const {data: response} = yield* call(Api.Auth.login, payload);
		ApiError.CHECK(response);

		if (response.success) {
			const {user} = response.success;

			yield* put(actions.userLoginSuccess(user));
		} else {
			yield* put(actions.userLoginFailed({message: "Login Failed"}));
		}
	} catch (e) {
		const code = get(e, "code");
		if (code === 515) {
			yield* put(actions.userAskForReturn());
		} else {
			yield* put(actions.userLoginFailed(e as IError));
			yield* put(actions.globalError(e as IError));
		}
	}
};

export const postForgotPasswordSaga = function* ({payload}: ISagaAction<IForgotPasswordPayload>) {
	try {
		const {data: response} = yield* call(Api.Auth.password_reset_request, payload);
		ApiError.CHECK(response);

		if (response.success) {
			yield* put(actions.postForgotPasswordSuccess());
		}
	} catch (e) {
		yield* put(actions.globalError(e as IError));
	}
};

export const postRestorePasswordSaga = function* ({payload}: ISagaAction<IRestorePasswordPayload>) {
	try {
		const {data: response} = yield* call(Api.Auth.password_reset, payload);
		ApiError.CHECK(response);

		if (response.success) {
			yield* put(actions.postRestorePasswordSuccess());
		}
	} catch (e) {
		yield* put(actions.globalError(e as IError));
		yield* put(actions.postRestorePasswordFailed(e as IError));
	}
};
