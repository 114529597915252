import * as React from "react";
import {Redirect, Route, RouteProps} from "react-router-dom";
import {useSelector} from "react-redux";
import {isLoggedIn} from "modules/selectors";
import {get} from "lodash";

interface IProps extends RouteProps {
	to?: string;
}

export const NotAuthOnlyRoute: React.FC<IProps> = (props) =>
	useSelector(isLoggedIn) ? (
		<Redirect to={props.to || get(props, "location.state.from.pathname", "/")} />
	) : (
		<Route {...props} />
	);
